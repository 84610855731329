import { NavLink } from "react-router-dom";
import styled, { createGlobalStyle, css } from "styled-components";

const colors = {
    "yellow": "yellow-500",
    "orange": "orange-500",
    "pink": "pink-500",
    "purple": "purple-500",
    "blue": "blue-500",
    "green": "green-500",
    "red": "danger-500",
    "black": "black",
    "gray": "gray-600",
} as const

type BoxTitle = {
    variant: "colorful" | "clean";
    color?: keyof typeof colors;
}


export const GlobalStyle = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;

    &::-webkit-scrollbar {
        width: 10px;
        background: ${p => p.theme["gray-300"]};
    }

    &::-webkit-scrollbar-thumb {
        background: ${p => p.theme["blue-500"]};
        border-radius: 999px;
        border: ${p => p.theme["gray-300"]} solid 2px;
    }
}

:focus {
    outline-color: white;
    outline: 2px solid #011e323e;
    outline-offset: 0;
}

a:focus-visible {
    text-decoration: underline;
}

html {
    scroll-behavior: smooth;
}

body {
    background: ${p => p.theme["background"]};
    min-height: 100vh;
    /* width: 100%; */
}

body, textarea, button {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: ${p => p.theme["text-md"]};
}

input, a, button, strong {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
}

strong {
    font-weight: bold;
}

h2 {
    font-size: ${p => p.theme["text-xl"]};
    color: ${p => p.theme["gray-900"]}
}

label {
    font-size: ${p => p.theme["text-md"]};
    font-weight: bold;
    color: ${p => p.theme["gray-800"]};
}

button {
    all: unset;
    box-sizing: border-box;
    border: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;

    background: ${p => p.theme["gray-100"]};
    border-radius: 6px;
    padding: 19.5px 20px;
    
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
    cursor: default;

    font-family: "Roboto", sans-serif;
    font-size: ${p => p.theme["text-lg"]};
    font-weight: 700;
    color: ${p => p.theme["white"]};
    text-transform: uppercase;

    &:hover {
        filter: brightness(0.95);
    }

    &:active {
        filter: brightness(0.9);
    }

    &:disabled {
        filter: saturate(0);
        cursor: not-allowed;
    }
}

::view-transition-image-pair(root) {
    isolation: auto;
  }
  
  ::view-transition-old(root),
  ::view-transition-new(root) {
    animation: none;
    mix-blend-mode: normal;
    display: block;
  }
`

export const CloseButton = styled.button`
    all: unset;

    width: 50px;
    height: 50px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: ${p => p.theme["danger-500"]};
    border-radius: 5px;
    color: ${p => p.theme["white"]};

    &:hover {
        filter: brightness(0.95);
    }

    &:active {
        filter: brightness(0.9);
    }
`

export const Input = styled.input`
    all: unset;
    box-sizing: border-box;
    background: ${p => p.theme["white"]};
    outline: 2px ${p => p.theme["gray-400"]} solid;
    outline-offset: -2px;
    border-radius: 6px;

    width: 100%;
    height: 50px;
    padding: 0 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;

    box-shadow: inset 0px 6px 0px ${p => p.theme["gray-200"]};
    cursor: text;

    font-size: ${p => p.theme["text-sm"]};
    font-weight: normal;

    &::placeholder {
        font-weight: 700;
        font-size: ${p => p.theme["text-sm"]};
        color: ${p => p.theme["gray-400"]};
    }

    &:focus {
        outline-color: ${p => p.theme["green-700"]};
        outline-offset: -2px;
        transition: all .1s;
    }

    &.error,
    &:invalid {
        outline-color: ${p => p.theme["danger-500"]};
        outline-offset: -2px;
        transition: all .1s;
    }

    &:disabled {
        outline: 1px solid #00000030;
        outline-offset: -1px;
        box-shadow: none;
        background: ${p => p.theme["gray-300"]};
        cursor: not-allowed;

        &::placeholder {
            color: ${p => p.theme["gray-500"]};
        }
    }
`

export const BadgesRow = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    img {
        padding: 8px;
        border-radius: 8px;
        background: ${p => p.theme["gray-200"]};
    }
`

export const BoxContentLink = styled(NavLink)`
    background: ${p => p.theme["gray-100"]};
    border-radius: 4px;
    padding: 8px;
    width: 100%;

    color: ${p => p.theme["blue-500"]};
    font-size: ${p => p.theme["text-sm"]};
    font-weight: bold;

    &:hover {
        background: ${p => p.theme["gray-200"]};
        transition: background 150ms;
    }

    &:active {
        filter: brightness(0.95);
        transition: filter 150ms;
    }

    :focus {
        outline: 2px solid ${p => p.theme["blue-500"]};
        transition: outline 50ms;
    }

    &.active {
        background: ${p => p.theme["gray-300"]};
        color: ${p => p.theme["black"]};
        cursor: default;

        outline-color: ${p => p.theme["black"]};
    }

    & + a {
        margin-top: 4px;
    }

    & *:last-child {
        margin-bottom: 0;
    }
`

export const BoxContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-top: 0;

    font-size: ${p => p.theme["text-sm"]};
    font-weight: 400;
    color: ${p => p.theme["black"]};

    span, p {
        display: block;
        margin-bottom: 12px;
    }

    & *:last-child {
        margin-bottom: 0;
    }
`

export const BoxSmallHeader = styled.h4`
    padding: 6px;
    text-align: center;
    color: ${p => p.theme["white"]};
    font-size: ${p => p.theme["text-md"]};
    font-weight: bold;

    background: ${p => p.theme["black"]};
    border-radius: 5px;
    margin: 10px;
    margin-top: 0px;
`

export const BoxTitle = styled.h2<BoxTitle>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 50px;
    border-radius: 6px;
    font-weight: 700;
    font-size: ${p => p.theme["text-xl"]};
    color: ${p => p.theme["white"]};

    user-select: none;
    margin: 10px;

    ${p => p.variant === "colorful" && css`
        background: ${p.theme["blue-500"]};

        /* colors */
        ${p.color === "blue" && css`background: ${p => p.theme["blue-500"]}`}
        ${p.color === "black" && css`background: ${p => p.theme["black"]}`}
        ${p.color === "gray" && css`background: ${p => p.theme["gray-600"]}`}
        ${p.color === "green" && css`background: ${p => p.theme["green-500"]}`}
        ${p.color === "orange" && css`background: ${p => p.theme["orange-500"]}`}
        ${p.color === "pink" && css`background: ${p => p.theme["pink-500"]}`}
        ${p.color === "purple" && css`background: ${p => p.theme["violet-500"]}`}
        ${p.color === "red" && css`background: ${p => p.theme["danger-500"]}`}
        ${p.color === "yellow" && css`background: ${p => p.theme["yellow-500"]}`}
    `}

    ${p => p.variant === "clean" && css`
        height: unset;
        padding: 16px 0;
        margin: 0;
        color: ${p => p.theme["gray-900"]};
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
        border-radius: 0;
    `}
`

export const Box = styled.section`
    border-radius: 6px;
    background: ${p => p.theme["white"]};
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    overflow: hidden;

    display: flex;
    flex-direction: column;
`

export const WarningBadge = styled.div`
    background: ${p => p.theme["danger-200"]};
    border: 1px solid ${p => p.theme["danger-500"]};
    width: 100%;
    padding: 24px 16px;
    border-radius: 8px;
    
    font-size: ${p => p.theme["text-sm"]};
    font-weight: 400;
    text-align: center;
    color: ${p => p.theme["danger-500"]};
`