import styled from "styled-components";

type HeaderProps = {
    color: "white" | "blue" | "orange" | "green"
}

export const ProductHeader = styled.header<HeaderProps>`
    display: flex;
    justify-content: space-between;

    h3 {
        font-size: ${p => p.theme["text-md"]};
        font-weight: bold;
        color: ${p => p.color === "white" ? p.theme["black"] : p.theme["white"] };
        

        padding: 12px 24px;
        border-radius: 999px;
        box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.15);

        // background colors
        background: ${p => p.color === "white" && p.theme['white']};
        background: ${p => p.color === "blue" && p.theme['blue-500']};
        background: ${p => p.color === "orange" && p.theme['orange-500']};
        background: ${p => p.color === "green" && p.theme['green-500']};
    }

    span {
        color: ${p => p.theme["white"]};
        padding: 8px 16px;
        border-radius: 999px;
        background: ${p => p.theme["black"]};
        height: fit-content;
    }
`

export const ProductContainer = styled.article`
    padding: 12px;
    border-radius: 8px;
    background: ${p => p.theme["white"]};
    border: 1px solid ${p => p.theme["gray-300"]};

    display: flex;
    flex-direction: column;
    gap: 16px;

    p, ul, li {
        font-size: ${p => p.theme["text-sm"]};
        font-weight: regular;
        color: ${p => p.theme["black"]};
    }

    li {
        list-style: none;

        &::before {
            content: "•";
            margin-right: 6px;
            color: ${p => p.theme["black"]};
        }
    }

    button {
        width: fit-content;
    }
`