import { FaDiscord, FaFacebookF, FaTwitter } from "react-icons/fa"
import { NavLink } from "react-router-dom"
import { GoToTopButton } from "../GoToTopButton/index"
import { FooterContainer, FooterContent, FooterHeader, FooterMenu, FooterSocialMedias, Line, SocialMediaRow, ThisFooter } from "./style"


export default function FooterComponent() {
    return (
        <FooterContainer>
            <FooterContent>
                <FooterHeader>
                    <FooterMenu>
                        <img src="/official-badge.png" alt="" />

                        <nav>
                            <h2>Menu rápido</h2>

                            <ul>
                                <li><NavLink to="/trabalheconosco">Trabalhe Conosco</NavLink></li>
                                <li><NavLink to="/comunidade/noticia">Últimas notícias</NavLink></li>
                                <li><NavLink to="/termosecondicoes">Termos e Condições</NavLink></li>
                                <li><NavLink to="/politicadeprivacidade">Política de Privacidade</NavLink></li>
                            </ul>
                        </nav>
                    </FooterMenu>

                    <FooterSocialMedias>
                        <h2>Redes sociais</h2>

                        <SocialMediaRow>
                            <a href="https://twitter.com/" className="twitter"><FaTwitter size={24} /></a>
                            <a href="https://www.facebook.com/" className="facebook"><FaFacebookF size={24} /></a>
                            <a href="https://discord.com/" className="discord"><FaDiscord size={24} /></a>
                        </SocialMediaRow>
                    </FooterSocialMedias>
                </FooterHeader>

                    <Line />

                <ThisFooter>
                    <div>
                    <strong>Habblive © 2015 - 2022</strong>
                        <span>Powered by <strong>Atlanta Servers BR</strong>. Design by <strong>Floricultor</strong>.</span>
                    </div>

                    <SocialMediaRow>
                            <a href="https://twitter.com/" className="twitter"><FaTwitter size={24} /></a>
                            <a href="https://www.facebook.com/" className="facebook"><FaFacebookF size={24} /></a>
                            <a href="https://discord.com/" className="discord"><FaDiscord size={24} /></a>
                    </SocialMediaRow>
                </ThisFooter>

                <GoToTopButton />
            </FooterContent>
        </FooterContainer>
    )
}