import { FullTitle } from "../../components/FullTitle";
import { Box, BoxTitle } from "../../styles/global";
import { Events } from "./Components/Events";
import { Promotions } from "./Components/Promotions";
import { TopDiamonds } from "./Components/TopDiamonds";
import { TopDuckets } from "./Components/TopDuckets";
import { TopPromocoins } from "./Components/TopPromocoins";
import { BadgesRow, HallOfFameContainer, HallOfFamePageLayout, InfoContentCol, InfoContentFooter, InfosContainer, RankingCard, RichestUsersContainer, RichestUsersWrapper, UserInfoCol, UserItem, UsersList } from "./style";

export function HallOfFame(){ 
    return (
        <HallOfFamePageLayout>
            <HallOfFameContainer>

                {/* competitions ----------- */}
                <Promotions />

                {/* events ----------------- */}
                <Events />

                {/* user status */}
                <Box>
                    <BoxTitle variant="colorful" color="black">
                        Meu Status
                    </BoxTitle>

                    <UsersList>
                        <UserItem>
                            <img src="https://habblive.in/imageeer.php?user=Batman&action=wav&size=b&head_direction=3&direction=3&gesture=sml" alt="" />
                            <UserInfoCol>
                                <strong>Floricultor</strong>
                                <span>10 Promoções</span>
                                <span>120 eventos</span>
                            </UserInfoCol>
                        </UserItem>
                    </UsersList>
                </Box>
            </HallOfFameContainer>

            <InfosContainer>
                <BoxTitle variant="clean">
                    O que é Hall da Fama?
                </BoxTitle>

                <InfoContentCol>
                    <p style={{textAlign: "center",}}>
                        <img src="https://cdn.discordapp.com/attachments/1014620322781220916/1053079667910909982/EzxgUrn.png" width={295} height={194} alt="Hall da Fama" />
                    </p>

                    <p>
                        O Hall da Fama foi criado com o intuito de promover os jogadores que mais se sobressaem nas atividades proporcionadas pela Equipe. A única maneira de entrar no TOP 5 de qualquer categoria é participando com frequência de Promoções e/ou Eventos elaborados todos os dias em nossa comunidade...
                    </p>

                    <p>
                        Quando falamos sobre Promoção, tanto faz ser sobre texto, visual, pixel art ou até mesmo construção. A mesma só irá lhe proporcionar 1 ponto nessa categoria caso esteja comprovado na notícia do concurso que realmente irá valer a pontuação. Por isso, confira a seguir outras informações relevantes:
                    </p>

                    <p>
                        » Os pontos são acumulados desde o primeiro dia do mês;
                        » Os concursos que valem ponto são identificados com símbolo: 
                        » Os usuários que ficarem primeiro lugar no fim ganharão raros;
                    </p>

                    <p>
                        A premiação de eventos, alterada há um tempo, conduz um ponto por cada evento que conseguir granjear. Ao final do mês, você poderá trocar as suas pontuações obtidas com a direção por raros exclusivos, que inclusive nunca foram colocados em nosso catálogo principal. Fique de olho nas novidades!
                    </p>
                </InfoContentCol>
                
                <InfoContentFooter>
                    <BadgesRow>
                        <img src="https://cdn.discordapp.com/attachments/451917579519524868/672969194316365834/cocozinha.gif" alt="" />
                        <img src="https://media.discordapp.net/attachments/1014620322781220916/1053080309727510688/O7NSyDf.png" alt="" />
                        <img src="https://media.discordapp.net/attachments/1014620322781220916/1053080319756095570/tgUjy3O.png" alt="" />
                    </BadgesRow>

                    <BadgesRow>
                        <img src="https://cdn.discordapp.com/attachments/1014620322781220916/1053080370502967418/pdjVJXg.png" alt="" />
                        <img src="https://cdn.discordapp.com/attachments/1014620322781220916/1053080379894014033/EClQoBJ.png" alt="" />
                        <img src="https://cdn.discordapp.com/attachments/1014620322781220916/1053080390623047680/rREct1r.png" alt="" />
                    </BadgesRow>
                </InfoContentFooter>
            </InfosContainer>

            <RichestUsersContainer>
                <FullTitle>
                    Usuários mais ricos online
                </FullTitle>

                <RichestUsersWrapper>
                    <TopDiamonds />
                    <TopDuckets />
                    <TopPromocoins />
                </RichestUsersWrapper>
            </RichestUsersContainer>
        </HallOfFamePageLayout>
    )
}