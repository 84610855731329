import styled from "styled-components";
import { ArticleContainer } from "../../components/Articles/style";

export const ArticlesGrid = styled.section `
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: min-content;
    gap: 20px;

    ${ArticleContainer} {
        width: 100%
    }

    @media (max-width: 1040px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`

export const MobileWrapContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 12px;

    input {
        flex: 1;
    }

    @media (max-width: calc(280px + 40px)) {
        flex-wrap: wrap;

        button {
            width: 100%
        }
    }
`

export const BoxContent = styled.div`
    margin: 0 10px 10px 10px;

    display: flex;
    flex-direction: row;
    gap: 12px;

    @media (min-width: 481px) {
        button {
            flex: 1;
            max-width: fit-content;
            flex-shrink: 0;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    @media (max-width: 481px) {
        flex-direction: column;
    }
`

export const Main = styled.main`
    width: calc(100% - 40px);
    max-width: 1000px;
    margin: auto;

    display: flex;
    flex-direction: column;
    gap: 30px;
`