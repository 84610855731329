import { Outlet } from "react-router-dom";
import { ConfigsLink, ConfigsLinkDanger, ConfigsPageLayout, TabNavigation, Wrapper } from "./style";

export function ConfigsLayout() {
    return (
        <Wrapper>
            <ConfigsPageLayout>
                <Outlet />
                <TabNavigation>
                    <ConfigsLink to="/configs" end>Configurações gerais</ConfigsLink>
                    <ConfigsLink to="/configs/senha">Mudar a senha</ConfigsLink>
                    <ConfigsLink to="/configs/email">Mudar o e-mail</ConfigsLink>
                    <ConfigsLinkDanger to="/configs/apagaramigos">Apagar os amigos</ConfigsLinkDanger>
                </TabNavigation>
            </ConfigsPageLayout>
        </Wrapper>
    )
}