export const defaultTheme = {
    "success-700": "#079D31",
    "success-500": "#00CC39",
    "success-200": "#1AE948",

    "danger-500": "#E91616",
    "danger-200": "#FFD3D3",

    "black": "#000000",

    "white": "#FFFFFF",

    "background": "#EFEFEF",

    "blue-gradient": "linear-gradient(180deg, #4FDFFF 0%, #4D9FFF 100%)",

    "yellow-500": "#EDB31E",
    "yellow-300": "#FFD600",

    "orange-500": "#F98600",

    "pink-500": "#FC59FF",

    "violet-500": "#9A3EE2",

    "blue-500": "#00A7EF",
    "blue-700": "#0760E7",

    "green-700": "#079D31",
    "green-500": "#00CC39",
    "green-400": "#25da18",
    "green-200": "#1AE948",
    
    "gray-1000": "#212529",
    "gray-900": "#343A40",
    "gray-800": "#484849",
    "gray-700": "#545454",
    "gray-600": "#5C5C5C",
    "gray-500": "#777777",
    "gray-400": "#BBBBBB",
    "gray-300": "#DDDDDD",
    "gray-200": "#E5E5E5",
    "gray-100": "#EEEEEE",

    "list-odd-200": "#F2F5FF",
    "list-odd-100": "#FFF4FF",

    "list-even-200": "#E9EEFF",
    "list-even-100": "#F4E9F5",

    // font-sizing

    "text-xl": "1.25rem",
    "text-lg": "1.125rem",
    "text-md": "1rem",
    "text-sm": "0.875rem",
    "text-xs": "0.75rem",
} as const;