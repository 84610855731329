export type User = {
    isLogged: boolean;
    id: number;
    username: string;
    figure?: string;
}

export const defaultUser: User ={
    isLogged: false,
    id: 0,
    username: "Guest"
}