import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Box } from "../../styles/global";

export const ConfigsLink = styled(NavLink)`
    background: ${p => p.theme["gray-100"]};
    border-radius: 4px;
    padding: 8px;
    width: 100%;

    color: ${p => p.theme["blue-500"]};
    font-size: ${p => p.theme["text-sm"]};
    font-weight: bold;

    &:hover {
        background: ${p => p.theme["gray-200"]};
        transition: background 150ms;
    }

    &:active {
        filter: brightness(0.95);
        transition: filter 150ms;
    }

    :focus {
        outline: 2px solid ${p => p.theme["blue-500"]};
        transition: outline 50ms;
    }

    &.active {
        background: ${p => p.theme["gray-300"]};
        color: ${p => p.theme["black"]};
        cursor: default;

        outline-color: ${p => p.theme["black"]};
    }
`

export const ConfigsLinkDanger = styled(ConfigsLink)`
    color: ${p => p.theme["danger-500"]};

    :focus {
        outline: 2px solid ${p => p.theme["danger-500"]};
    }
`

export const TabNavigation = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 10px;

    height: auto;
    align-self: flex-start;

    @supports(not(gap: 30px)) {
        margin-left: 30px;
        min-width: 370px;
        max-width: 370px;

        & > *:not(:last-child) {
            margin-bottom: 4px;
        }
    }
`

export const ConfigsPageLayout = styled.div`
    width: 100%;
    max-width: 1000px;
    margin: auto;

    display: grid;
    grid-template-columns: 600px 370px;
    gap: 30px;

    @media (max-width: 1040px) {
        grid-template-columns: 60% 1fr;
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        
        ${TabNavigation} {
            grid-row: 1;
            
            a {
                padding: 12px;
                font-size: ${p => p.theme["text-md"]};
            }
        }
    }

    @supports (not(display: grid)) {
        display: flex;

        main {
            width: 100%;
        }
    }
`

export const Wrapper = styled.div`
    width: 100%;
    padding: 0 20px;
`