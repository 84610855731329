import { Button } from "../../../components/Button";
import { BoxTitle } from "../../../styles/global";
import { Container, Form } from "./style";

export function ClearFriendsConfigs() {
    return (
        <Container>
            <BoxTitle variant="clean">
                Apagar todos os amigos
            </BoxTitle>

            <Form>
                <label>
                    Isso é irreversível, tem certeza disso?
                </label>

                <Button type="submit" variant="danger">
                    Confirmar
                </Button>
            </Form>
        </Container>
    )
}