import { Outlet, ScrollRestoration } from "react-router-dom";
import { HeaderLogged } from "../../components/Header/loggedHeader";
import { HeaderUnlogged } from "../../components/Header/unloggedHeader"
import { LayoutContainer } from "./style";
import { usePath } from "../../hooks/usePath";
import FooterComponent from "../../components/FooterComponent";
import { useUser } from "../../hooks/users/useUser";
import { LoadingScreen } from "../../components/LoadingScreen";
import { useLoading } from "../../hooks/loading/useLoading";

export function DefaultLayout() {
    const { user: { isLogged, username, figure } } = useUser();
    const page = usePath();
    const { isLoading } = useLoading();

    return (
        <>
            {isLoading ? <LoadingScreen /> : <></>}
            <LayoutContainer>
                <HeaderLogged page={page} nickname={username} figure={figure} />
                <Outlet />
                <FooterComponent />
                <ScrollRestoration />
            </LayoutContainer>
        </>
    )
}