import { NavLink, useLocation } from "react-router-dom";
import { IMAGER_URL } from "../../Config";
import { HeaderContainer, HeaderContents, MDNavigationMenu, MenuLinks, NavigationContainer, OnlineUsersContainer, OnlineUsersTooltip, SMNavigationMenu, SubNavContainer } from "./styles";
import { MobileNavDialog } from "./subcomponents/DialogElements";
import { useUser } from "../../hooks/users/useUser";

type HeaderType = {
    page: "/" | "/comunidade" | "/loja" | "/halldafama";
    nickname: string;
    figure?: string;
}

type LinkType = {
    label: string,
    path: string,
    external?: boolean,
    needLogin?: boolean,
    destructive?: boolean,
}

type SubNavPathesType = {
    "/": LinkType[];
    "/comunidade": LinkType[];
    "/loja": LinkType[];
    "/halldafama": LinkType[];
}


export const subNavPathes: SubNavPathesType = {
    "/": [
        {
            label: "Home",
            path: "/"
        },
        {
            label: "Configurações",
            path: "/configs",
            needLogin: true
        },
        {
            label: "Perfil",
            path: "/perfil",
            needLogin: true
        },
        {
            label: "Calendário de Atividades",
            path: "https://hlive.ovh/calendario",
            external: true
        },
        {
            label: "Promoções Ativas",
            path: "/noticia/promocoesativas"
        },
        {
            label: "Colunas Semanais",
            path: "/noticias/colunassemanais"
        },
        {
            label: "Comunidade no Discord",
            path: "https://discord.com/app",
            external: true
        },
        {
            label: "Sair",
            path: "/logout",
            destructive: true,
            needLogin: true
        }
    ],
    "/comunidade": [
        {
            label: "Vip",
            path: "/comunidade"
        },
        {
            label: "Staff",
            path: "/comunidade/staff"
        },
        {
            label: "Colaboração",
            path: "/comunidade/colab"
        },
        {
            label: "GEA",
            path: "/comunidade/gea"
        },
        {
            label: "Oficiais",
            path: "/comunidade/oficiais"
        },
        {
            label: "Notícias",
            path: "comunidade/noticia"
        },
        {
            label: "Arquivo de Notícias",
            path: "/comunidade/arquivo"
        },
        {
            label: "Valores",
            path: "/comunidade/valores"
        },
        {
            label: "Países",
            path: "/comunidade/paises"
        },
        {
            label: "Server do Discord",
            path: "https://discord.com/app",
            external: true
        }
    ],
    "/loja": [
        {
            label: "Pacotes de raros",
            path: "/loja"
        },
        {
            label: "Planos de VIP",
            path: "/loja/planosdevip"
        },
        {
            label: "Mudança de nickname",
            path: "/loja/mudarnickname"
        }
    ],
    "/halldafama": [
        {
            label: "Hall da Fama",
            path: "/halldafama"
        },
    ],
}


export function HeaderLogged({ page = "/", nickname, figure }: HeaderType) {
    const { user } = useUser();
    const currentSubnavLinks = (user.isLogged ? subNavPathes[page] : subNavPathes[page].filter(x => !x.needLogin));
    const wavingUser = `${IMAGER_URL}${figure}&action=wav&size=b&head_direction=3&direction=3&gesture=sml`


    return (
        <HeaderContainer>
            <HeaderContents>


                {/* menu de navegação para desktop */}
                <NavLink to="/" className="logo">
                    <img src="/logo.png" id="logo" alt="Habblive" />
                </NavLink>

                <MDNavigationMenu>
                    <NavigationContainer>
                        <MenuLinks>
                            <NavLink to="/" end>{nickname ?? "Inicio"}</NavLink>
                            <NavLink to="/comunidade">Comunidade</NavLink>
                            <NavLink to="/loja">Loja</NavLink>
                            <NavLink to="/halldafama">Hall da Fama</NavLink>
                            <NavLink className="secondary" to="/betaclientv2">Jogar</NavLink>
                        </MenuLinks>
                    </NavigationContainer>

                    <SubNavContainer>
                        {currentSubnavLinks.map((each: LinkType) => {
                            return (
                                each.external === true ?
                                    <a key={each.label} target="_blank" href={each.path}>{each.label}</a>
                                    :
                                    each.destructive === true ?
                                        <NavLink key={each.label} className="destructive" to={each.path}>{each.label}</NavLink>
                                        :
                                        each.path === "/" || each.path === "/comunidade" || each.path === "/loja" ?
                                            <NavLink key={each.label} to={each.path} end>{each.label}</NavLink>
                                            :
                                            <NavLink key={each.label} to={each.path}>{each.label}</NavLink>
                            )
                        })}
                    </SubNavContainer>
                </MDNavigationMenu>


                {/* menu de navegação para mobile */}
                <SMNavigationMenu>
                    <MobileNavDialog isLogged={true} />
                    <OnlineUsersTooltip><strong>0000</strong> usuários!</OnlineUsersTooltip>
                </SMNavigationMenu>


                {/* contador de usuários online */}
                <OnlineUsersContainer>
                    <OnlineUsersTooltip><strong>0000</strong> usuários!</OnlineUsersTooltip>
                    <img src={wavingUser} alt="Habbo avatar wavinge" />
                </OnlineUsersContainer>


            </HeaderContents>
        </HeaderContainer>
    )
}