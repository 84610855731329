import styled from "styled-components";

export const Title = styled.h2`
    font-size: ${p => p.theme["text-xl"]};
    font-weight: bold;
    color: ${p => p.theme["gray-900"]};
    white-space: nowrap;
`

export const Line = styled.div`
    flex: 1;
    height: 1px;
    background: ${p => p.theme["gray-400"]};
    border-radius: 999px;

    @supports (not(gap: 24px)) {
        margin-left: 24px;
    }
`

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 24px;
`