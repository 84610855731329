import { useContext } from "react";
import { ApisContext } from "../../Contexts/ApisContext";
import { BadgesRow, Box, BoxContent, BoxContentLink, BoxTitle } from "../../styles/global";
import { dateFormatter } from "../../utils/dateFormatter";
import { AsideContainer, InfosContainer, VipPageLayout, MainContainer, StatusBadge, VipCard } from "./style";


export function Vip() {
    const { VipUsersInfo } = useContext(ApisContext)

    return (
        <VipPageLayout>
            <MainContainer>

                {VipUsersInfo.map(({nickname, mission, isOnline, endlineDate}) => {
                    const userImg = `https://habblive.in/imageeer.php?user=${nickname}&action=wav&size=b&head_direction=3&direction=2&gesture=sml`

                    return (
                        <VipCard key={nickname}>
                            <img src={userImg} alt="" />
                            <InfosContainer>
                                <h4>{nickname}</h4>

                                <span><strong>Missão: </strong>{mission}</span>
                                <span><strong>Vip até: </strong>{dateFormatter.format(new Date(endlineDate))}</span>

                                <StatusBadge isOnline={isOnline}>{isOnline ? "Online" : "Offline"}</StatusBadge>
                            </InfosContainer>
                        </VipCard>
                    )
                })}

            </MainContainer>

            <AsideContainer>
                
                <Box>
                    <BoxTitle variant="colorful">
                        O que são VIPs
                    </BoxTitle>

                    <BoxContent>
                        <span>
                            VIPS são os jogadores da elite do Habblive. Esses contam com vários benefícios, de acesso a itens exclusivos à emblemas únicos.
                        </span>

                        <BadgesRow>
                            <img src="/emblema-vip.gif" alt="" />
                        </BadgesRow>
                    </BoxContent>
                </Box>

                <Box>
                    <BoxTitle variant="colorful">
                        Torne-se VIP
                    </BoxTitle>

                    <BoxContent>
                    <span>
                        Você pode tornar-se um dos membros dessa alta sociedade, basta clicar no link abaixo para saber mais sobre todas as vantagens e conhecer os preços.
                    </span>

                    <BoxContentLink to="/loja/planosdevip">
                        Ser VIP
                    </BoxContentLink>
                    </BoxContent>
                </Box>

            </AsideContainer>
        </VipPageLayout>
    )
}