import { Column, LoadingPageLayout, LogoImg, SpinnerImg } from "./style";

export function LoadingScreen() {
    return (
        <LoadingPageLayout>
            <Column>
                <LogoImg src="/logo.png" alt="" draggable={false} />
                <SpinnerImg src="/spinner.png" alt="" draggable={false} />
            </Column>
        </LoadingPageLayout>
    )
}