import styled from "styled-components";

export const ThisFooter = styled.footer`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 5px;
        
        @supports (not(gap: 5px)) {
            & > strong {
                margin-bottom: 5px;
            }
        }
    }

    span {
        color: ${p => p.theme["gray-400"]};
        text-align: center;
    }

    strong {
        font-weight: bold;
        color: ${p => p.theme["white"]};
        text-align: center;
    }
`

export const Line = styled.div`
    width: 100%;
    height: 1px;
    border-radius: 999px;
    background: ${p => p.theme["gray-700"]};

    @supports (not(gap: 40px)) {
        margin: 40px 0;
    }
`

export const SocialMediaRow = styled.div`
    display: flex;
    gap: 15px;

    @supports (not(gap: 15px)) {
        a + a {
            margin-left: 15px;
        }
    }

    a {
        width: 40px;
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        
        color: ${p => p.theme["white"]};

        &.twitter { background: ${p => p.theme["blue-500"]}; }
        &.facebook { background: #415FCA; }
        &.discord { background: #5F82FF; }

        &:hover {
            filter: brightness(0.9);
            outline: 4px solid #00000020;
        }

        &:active {
            filter: brightness(0.8);
        }
    }
`

export const FooterSocialMedias = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 860px) {
        display: none;

    }
`

export const FooterMenu = styled.div`
    display: flex;
    gap: 32px;
    align-items: center;

    img {
        padding: 10px;
        border-radius: 5px;
        background: ${p => p.theme["gray-1000"]};

        @supports (not(gap: 12px)) {
            margin-right: 30px;
        }
    }

    nav {
        display: flex;
        flex-direction: column;
        gap: 32px;

        ul {
            display: flex;
            gap: 16px;

            @supports (not(gap: 16px)) {
                li + li {
                    margin-left: 16px;
                }
            }

            li {
                list-style: none;

                a {
                    font-size: ${p => p.theme["text-sm"]};
                    font-weight: bold;
                    color: ${p => p.theme["gray-300"]};

                    &:hover {
                        color: ${p => p.theme["blue-500"]};
                        transition: color 150ms;
                    }

                    &:focus {
                        outline-color: #2196f336;
                        outline-width: 3px;
                        background: #2196f336;
                        border-radius: 1px;
                    }
                }
            }
        }
    }
`

export const FooterHeader = styled.header`
    display: flex;
    justify-content: space-between;

    h2 {
        font-size: ${p => p.theme["text-lg"]};
        font-weight: bold;
        color: ${p => p.theme["white"]};

        @supports (not(gap: 20px)) {
            margin-bottom: 20px;
        }
    }

    @media (max-width: 586px) {
        nav ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }
`

export const FooterContent = styled.div`
    padding: 50px 0;

    width: 100%;
    max-width: 1000px;
    margin: auto;

    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (min-width: 860px) {
        ${ThisFooter} ${SocialMediaRow} {
            display: none;
        }
    }

    @media (max-width: 860px) {
        align-items: flex-start;

        ${ThisFooter} {
            flex-direction: row;
            justify-content: space-between;

            div {
                align-items: flex-start;
            }
        }

        ${SocialMediaRow} {
            flex-direction: row;
        }
    }

    @media (max-width: 700px) {
        align-items: center;

        img {
            display: none;
        }

        h2 {display: none;}

        ${ThisFooter} {
            flex-direction: column;
            gap: 24px;

            div {
                align-items: center;
            }
        }
    }

    position: relative;
`

export const FooterContainer = styled.footer`
    width: 100%;
    padding: 0 20px;

    background: ${p => p.theme["gray-900"]};
    
    @supports (not(display: grid)) {
        margin-top: 50px;
    }
`