import { Box, BoxContent, BoxContentLink, BoxSmallHeader, BoxTitle } from "../../styles/global";
import { TrackerDialog } from "./Components/Tracker";
import { AsideContainer, CardContainer, CardHeader, CardWrapper, InfosCol, ItemPicture, ValoristsPageLayout, MainContainer, StatusChip } from "./style";


export function Valorists() {
    return (
        <ValoristsPageLayout>
            <MainContainer>
                <CardContainer>
                    <TrackerDialog item="dragão">
                        <ItemPicture className="item" src="https://habbox.com/wp-content/uploads/2018/10/teal-dragon-lamp.png" alt="" />
                    </TrackerDialog>

                    <CardWrapper>
                        <CardHeader>
                            <h2>Nome do raro aqui</h2>
                            <StatusChip increased={true}>Subiu</StatusChip>
                        </CardHeader>

                        <InfosCol>
                            <li><img src="/chair.png" alt="" />5931</li>
                            <li><img src="/cambios.png" alt="" />4304 diamantes</li>
                            <li><img src="/calendar.png" alt="" />12/10/2022</li>
                        </InfosCol>
                    </CardWrapper>
                </CardContainer>
            </MainContainer>

            <AsideContainer>
                <Box>
                    <BoxTitle variant="colorful">Categorias</BoxTitle>
                    <BoxContent>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Hiper raros</BoxContentLink>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Super raros</BoxContentLink>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Totens</BoxContentLink>
                    </BoxContent>

                    <BoxSmallHeader>Colecionáveis</BoxSmallHeader>

                    <BoxContent>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Serpentes</BoxContentLink>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Ventiladores</BoxContentLink>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Sorveteiras</BoxContentLink>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Pilares</BoxContentLink>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Almofadas</BoxContentLink>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Preguiças</BoxContentLink>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Hipopótamos</BoxContentLink>
                    </BoxContent>

                    <BoxSmallHeader>LTDS</BoxSmallHeader>

                    <BoxContent>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Voodos</BoxContentLink>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Sorveteiras</BoxContentLink>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Serpentes</BoxContentLink>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Pilares</BoxContentLink>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Ovos</BoxContentLink>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Almofadas</BoxContentLink>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Máquinas de fumaça</BoxContentLink>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Toldos</BoxContentLink>
                        <BoxContentLink to={`/comunidade/valores/${1}`}>Aleatórios</BoxContentLink>
                    </BoxContent>

                </Box>
            </AsideContainer>
        </ValoristsPageLayout>
    )
}