import styled, { css } from "styled-components";

type StatusChipProps = {
    increased?: boolean;
}

// Aside

export const AsideContainer = styled.aside`
    @supports (not(display: grid)) {
        width: 100%;
        max-width: 370px;
    }
`


// Rare Card

export const InfosCol = styled.ul`
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid ${p => p.theme["gray-400"]};
    border-radius: 4px;

    li {
        padding: 10px;
        font-size: ${p => p.theme["text-md"]};
        color: ${p => p.theme["gray-800"]};
        list-style: none;
        background: ${p => p.theme["white"]};

        display: flex;
        align-items: center;

        &:not(:last-child) {
            border-bottom: 1px solid ${p => p.theme["gray-400"]};
        }

        &:hover {
            filter: brightness(0.97);
            img {
                background: ${p => p.theme["gray-200"]};
            }
        }

        &:first-child { border-radius: 5px 5px 0 0; }
        &:last-child { border-radius: 0 0 5px 5px; } 
    }

    img {
        width: 20px;
        height: 20px;
        object-fit: none;
        object-position: center center;
        margin-right: 5px;

        box-sizing: content-box;
        padding: 4px;
        border-radius: 999px;
        background: ${p => p.theme["gray-100"]};
    }
`

export const StatusChip = styled.span<StatusChipProps>`
    border-radius: 999px;
    padding: 4px 16px;
    font-size: ${p => p.theme["text-sm"]};
    font-weight: 700;
    color: ${p => p.theme["white"]};
    user-select: none;

    ${p => p.increased === true
    ? css`
        background: ${p => p.theme["green-500"]};
    `
    : css`
        background: ${p => p.theme["danger-500"]};
    `}
`

export const CardHeader = styled.header`
    flex: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        font-size: ${p => p.theme["text-md"]};
        font-weight: 700;
        color: ${p => p.theme["black"]};
    }

    @supports (not(gap: 16px)) {
        margin-bottom: 16px;
    }
`

export const CardWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const ItemPicture = styled.img`
    width: 150px;
    height: auto;
    object-fit: none;
    object-position: center;
    border-radius: 8px;
    cursor: pointer;

    &.item {
        background: ${p => p.theme["gray-100"]};
    }

    &:hover {
        filter: brightness(0.95);
    }

    &:active {
        filter: brightness(0.9);
    }

    @media (max-width: 481px) {
        width: 100%;
        height: 175px;
        max-height: 175px;
    }

    @supports (not(gap: 12px)) {
        margin-right: 12px;
    }
`

export const CardContainer = styled.article`
    display: flex;
    gap: 12px;

    width: 100%;
    padding: 12px;

    background: ${p => p.theme["white"]};
    border: 1px solid ${p => p.theme["gray-300"]};
    border-radius: 8px;

    @media (max-width: 481px) {
        flex-direction: column;
    }
`

export const MainContainer = styled.main`
    display: flex;
    flex-direction: column;
    gap: 30px;

    @supports (not(display: grid)) {
        width: 100%;
        max-width: 600px;
        margin-right: 30px;
    }

    @supports (not(gap: 30px)) {
        & > *:not(:last-child) {
            margin-bottom: 30px;
        }
    }
`

export const ValoristsPageLayout = styled.div`
    width: calc(100% - 40px);
    max-width: 1000px;
    margin: 0 auto;

    display: grid;
    grid-template-columns: 600px 1fr;
    gap: 30px;
    
    @media (max-width: 1040px) {
        grid-template-columns: 60% 1fr;
    }
    
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    @supports (not(display: grid)) {
        display: flex;
    }
`