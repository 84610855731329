import styled from "styled-components";
import { Box } from "../../../styles/global";

export const ButtonsRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    
    gap: 12px;
    padding: 10px;
`

export const PaddingWrapper = styled.label`
    display: flex;
    flex-direction: column;
    gap: 6px;

    padding: 0 10px;
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const Container = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
`