import { CoinsRow, HideableUserInfos, HomePageView, HotelImage, PlayRow, RightMarginImg, ShowMoreButton, UserInformationRow, UserInformationsColumn } from "./style"
import { FaAngleDown } from "react-icons/fa";
import { OnlineFriendsDialog } from "../FriendsDialog";
import { useContext } from "react";
import { UserContext } from "../../../Contexts/UserContext";

export function HomeView() {
    const {userInfos: {nickname, friendsRequest, onlineFriends, id, mission}} = useContext(UserContext)

    return (
        <HomePageView>
            <HotelImage>
                <span>{nickname}</span>
                <img src="//habbo.city/habbo-imaging/walkgif?figure=hd-208-1370.hr-990000223-1036.ch-990000960-66.he-989999972-62.lg-3136-110.cc-989999970-66-62.sh-3089-66&size=b&direction=2&head_direction=3&gesture=sml&action=wav&size=l?74" alt="" />
            </HotelImage>

            <PlayRow>
                <a href="jogar" target="_blank">Jogar flash</a>
                <a href="jogar" target="_blank">Jogar nitro</a>
            </PlayRow>

            <UserInformationsColumn>
                <UserInformationRow variant="default">
                    <div>
                        <strong>Missão:</strong>
                        <span className="ellipsis">{mission}</span>
                    </div>
                    <div>
                        <strong>ID:</strong>
                        <span>{id}</span>

                        <ShowMoreButton onClick={ExpandUserInfos} id="openMoreContentButton">
                            <FaAngleDown size={20} />
                        </ShowMoreButton>
                    </div>
                </UserInformationRow>
                <HideableUserInfos id="hideableAreaParent" data-closed>
                    <div id="hideableArea">
                        <UserInformationRow variant="dark">
                            <div>
                                <CoinsRow>
                                    <div>
                                        <span className="coin diamond"><img src="/diamonds.png" alt="" /></span>
                                        <span>88888888</span>
                                    </div>

                                    <div>
                                        <span className="coin gold"><img src="/cambios.png" alt="" /></span>
                                        <span>88888888</span>
                                    </div>

                                    <div>
                                        <span className="coin ducket"><img src="/duckets.png" alt="" /></span>
                                        <span>88888888</span>
                                    </div>
                                </CoinsRow>
                            </div>
                            <div>
                                <strong>Último login:</strong>
                                <span>17/10/22</span>
                            </div>
                        </UserInformationRow>

                        <UserInformationRow variant="default">
                            <div>
                                <RightMarginImg src="/friend.png" alt="" />
                                <span>Você tem <strong>{friendsRequest}</strong> pedidos de amizade pendentes.</span>
                            </div>
                            <div>
                                <OnlineFriendsDialog onlineUsers={onlineFriends}>Amigos online</OnlineFriendsDialog>
                            </div>
                        </UserInformationRow>
                    </div>
                </HideableUserInfos>
            </UserInformationsColumn>
        </HomePageView>
    )
}

function ExpandUserInfos() {
    const toggleableArea = document.querySelector("#hideableAreaParent") as HTMLElement
    const toggleButton = document.querySelector("#openMoreContentButton") as HTMLElement
    const normalHeight = document.querySelector("#hideableArea")?.scrollHeight

    if(toggleableArea!.hasAttribute('data-closed')) {
        toggleableArea!.removeAttribute('data-closed')
        toggleableArea.style.height=`${normalHeight}px`
        toggleButton.classList.add("opened")
    } else {
        toggleableArea!.toggleAttribute('data-closed')
        toggleableArea.style.height=`0`
        toggleButton.classList.remove("opened")
    }
}