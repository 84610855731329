import styled from "styled-components";
import { Article } from "../../components/Articles";
import { Box } from "../../styles/global";

export const LastArticlesRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    
    @media (max-width: 1060px) {
        flex-wrap: wrap;

        a {
            width: 100%;
            max-width: unset;
        }
    }
`

export const RegisteredUserItem = styled.div`
    width: 65px;
    height: 65px;
    flex-shrink: 0;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${p => p.theme["gray-200"]};
    border-radius: 5px;

    position: relative;
    top: 0;
    transition: all 150ms;

    @media (min-width: 1060px) {
        &:hover {
            background: ${p => p.theme["blue-500"]};
            box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
            top: -2px;
        }
    }
    
    &:hover {
        background: ${p => p.theme["blue-500"]};
    }

    img {
        object-fit: none;
        object-position: center;
        width: 100%;
        height: 100%;
    }

    @supports (not(gap: 12px)) {
        &:not(:nth-child(-1n + 6)) {
            margin-top: 12px;
        }

        &:not(:first-child) {
            margin-left: 12px;
        }

        &:nth-child(7n) {
            margin-left: 0;
        }
    }
`

export const RegisteredUsers = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;

    margin: 16px 10px;
`

export const Wrapper = styled.div`
    all: unset;
    position: relative;
    flex: 1;

    button {
        margin: 0 10px 10px 10px;
    }

    ${Box} {
        position: absolute;
        right: 0;
        bottom: 0;
    }
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 460px;

    label {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    label + label {
        margin-top: 12px;
    }

    div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 4px;

        margin-top: 12px;
    }

    a {
        font-weight: bold;
        color: ${p => p.theme["blue-500"]};
    }

    @supports (not(gap: 32px)) {
        margin-right: 32px;
    }
`

export const DarkContainer = styled.section`
    background: ${p => p.theme["gray-300"]};
    padding: 18px;

    width: 100%;
    border-radius: 4px;
    margin-top: 32px;

    display: flex;
    flex-direction: row;
    gap: 32px;
    justify-content: space-between;
    position: relative;

    @media (max-width: 1060px) {
        flex-direction: column;

        ${Box} {
            position: relative;

            ${RegisteredUsers} {
                flex-wrap: nowrap;
                overflow-x: scroll;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
        
        ${Form} {
            max-width: unset;
        }
    }
`

export const MainContent = styled.div`
    width: 100%;
    max-width: 1000px;
    margin: auto;

    display: flex;
    flex-direction: column;
    gap: 30px;

    @supports (not(gap: 30px)) {
        & > *:not(:last-child) {
            margin-bottom: 30px;
        }
    }
`

export const MainContainer = styled.main`
    width: 100%;
    padding: 0 20px;
`