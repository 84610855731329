import { ReactNode } from "react";
import { ModalCloseButton, ModalContainer, ModalContent, ModalDescription, ModalHeader, ModalOverlay, ModalPortal, ModalTitle, ModalTrigger } from "../../../../styles/DialogStyle";
import { CloseButton } from "../../../../styles/global";
import { FaTimes } from "react-icons/fa"
import { Amount, Header, UserInfos, UsersCard, UsersCol } from "./style";

type TrackerProps = {
    item: string;
    children: ReactNode;
}

export function TrackerDialog(props: TrackerProps) {
    return (
        <ModalContainer>
            <ModalTrigger asChild>
                {props.children}
            </ModalTrigger>

            <ModalPortal>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader variant="colorful" color="yellow">
                        <ModalTitle>Rastreador de usuários</ModalTitle>
                        <ModalDescription>Exibe uma lista dos usuários que possuem esse raro.</ModalDescription>

                        <ModalCloseButton asChild>
                            <CloseButton type="button" aria-label="Close"><FaTimes size={20} /></CloseButton>
                        </ModalCloseButton>
                    </ModalHeader>

                    <Header>
                        <h2>Usuário</h2>
                        <h2>Unidades</h2>
                    </Header>

                    <UsersCol>
                        <UsersCard>
                            <UserInfos>
                                <img src="https://habblive.in/imageeer.php?user=Batman&action=wav&size=b&head_direction=3&direction=3&gesture=sml&headonly=1" alt="" />
                                <span>Nickname</span>
                            </UserInfos>
                            <Amount>3</Amount>
                        </UsersCard>

                    </UsersCol>
                </ModalContent>
            </ModalPortal>
        </ModalContainer>
    )
}