import * as PrimitiveDialog from "@radix-ui/react-dialog"
import styled, { keyframes } from "styled-components"
import { BoxTitle } from "./global"

const open = keyframes`
    from {
        transform: scale(0.8) translateX(-50%) translateY(-50%);
        opacity: 0;
    }

    to {
        transform: scale(1) translateX(-50%) translateY(-50%);
        opacity: 1;
    }
`

const close = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
`

export const ModalCloseButton = styled(PrimitiveDialog.Close)`
    :is(&, & button):focus {
        outline: 3px solid #ff000036;
    }
`

export const ModalDescription = styled(PrimitiveDialog.Description)`
    display: none;
`

export const ModalTitle = styled(PrimitiveDialog.Title)``

export const ModalWrapper = styled.div`
    overflow-y: overlay;
    &::-webkit-scrollbar {
        border-radius: 999px;
    }
`

export const ModalContent = styled(PrimitiveDialog.Content)`
    width: calc(100vw - 20px);
    max-width: 900px;
    height: auto;
    max-height: calc(100vh - 20px);
    padding: 20px;
    
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid ${p => p.theme["gray-400"]};
    border-radius: 12px;
    
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    position: fixed;
    top: 50%;
    left: 50%;
    animation-fill-mode: both;
    overflow: hidden;

    &[data-state="open"] {
        animation: ${open} 200ms cubic-bezier(0, 1.32, 0.16, 1.11);
        transform: scale(1) translateX(-50%) translateY(-50%);
        opacity: 1;
        transition: inset .1s .3s;
    }

    &[data-state="closed"] {
        animation: ${close} 100ms ease-out;
        transform: scale(0.8) translateX(-50%) translateY(-50%);
        transition: all .1s .2s;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        z-index: -1;
        backdrop-filter: blur(20px);
        background: #ffffffaa;

        @supports (not(-webkit-backdrop-filter: none)) or (not (backdrop-filter: none)) {
            background: rgba(256, 256, 256, 1);
        }
    }

    &:focus {
        outline: none;
    }
`

export const ModalOverlay = styled(PrimitiveDialog.Overlay)`
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        -webkit-backdrop-filter: blur(1px);
        backdrop-filter: blur(1px);
        background-color: rgba(0, 0, 0, 0.1);  
    }

    @supports (not(-webkit-backdrop-filter: none)) or (not (backdrop-filter: none)) {
        background: rgba(0 ,0 ,0 , .6);
    }
`

export const ModalPortal = styled(PrimitiveDialog.Portal)``

export const ModalHeader = styled(BoxTitle).attrs({
    as: "div"
})`
    justify-content: space-between;
    padding: 8px;
    padding-left: 16px;
    max-height: unset;
    height: auto;

    margin: 0;

    h2 { 
        color: ${p => p.theme["white"]};
    }
`

export const ModalTrigger = styled(PrimitiveDialog.Trigger)`
    background: ${p => p.theme["blue-500"]};
`

export const ModalContainer = styled(PrimitiveDialog.Root)``