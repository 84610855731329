import { ReactNode, useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { CloseButton, Input } from "../../../styles/global";
import { Button } from "../../../components/Button";
import { ModalCloseButton, ModalContainer, ModalContent, ModalDescription, ModalHeader, ModalOverlay, ModalPortal, ModalTitle, ModalTrigger } from "../../../styles/DialogStyle";
import { Form, FormRow, ImgBackground } from "./style"
import { GenderRadioGroup } from "../GenderRadioGroup";
import Api from "../../../App/Api";


type DefaultDialogProps = {
    children: ReactNode,
    open?: boolean
}

export function DefaultDialog(props: DefaultDialogProps) {

    const [captcha, setCaptcha] = useState<string>(null);
    const [onUpdateCaptcha, setOnUpdateCaptcha] = useState<boolean>(false);

    const reloadCaptcha = async () => {
        if(onUpdateCaptcha) return;
        setOnUpdateCaptcha(true);
        const value = await Api.getInstance().get('/user/captcha', {responseType:'blob'});
        const imageObjectURL = URL.createObjectURL(value);
        setCaptcha(imageObjectURL);

        setOnUpdateCaptcha(false);
    }

    const onOpenChange = (open: boolean) => {
        if(open) reloadCaptcha();
    }
    return (
        <ModalContainer modal onOpenChange={onOpenChange}>
            <ModalTrigger>{props.children}</ModalTrigger>

            <ModalPortal>
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader variant="colorful" color="yellow">
                        <ModalTitle>Registre-se</ModalTitle>
                        <ModalDescription>Crie uma conta no hotel gratuitamente.</ModalDescription>

                        <ModalCloseButton asChild>
                            <CloseButton type="button" aria-label="Close" title="Fechar"><FaTimes size={20} /></CloseButton>
                        </ModalCloseButton>
                    </ModalHeader>

                    <ImgBackground src="/hotel.png" alt="" />

                    <Form>

                        <label htmlFor="nickname">Nickname</label>                            
                        <Input placeholder="Joaozinho" aria-label="Nickname do usuário" name="nickname" id="nickname" />

                        <label htmlFor="email">E-mail</label>
                        <Input placeholder="emailvalido@live.com" aria-label="E-mail válido de usuário" name="email" id="email" />

                        <label htmlFor="senha">Senha</label>
                        <Input placeholder="****" aria-label="Senha da nova conta do usuário" name="senha" id="senha" />

                        <label htmlFor="senharepetida">Repita a senha</label>
                        <Input placeholder="****" aria-label="Repita a senha escolhida" name="senharepetida" id="senharepetida" />

                        <FormRow>
                            <img src={captcha} alt="" className="captcha" />
                        </FormRow>
                        <label htmlFor="captchacode">Captcha (<a onClick={reloadCaptcha}>No lo entiendo</a>)</label>
                        <Input placeholder="****" aria-label="Repita a senha escolhida" name="senharepetida" id="senharepetida" />
                        <Button variant="success">Registre-me</Button>
                    </Form>
                </ModalContent>
            </ModalPortal>
        </ModalContainer>
    )
}