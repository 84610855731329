import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Box } from "../../styles/global";
import { Container } from "./CoinsField/style";

export const BadgeToolTipContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    span {
        font-size: ${p => p.theme["text-sm"]};

        &:not(:first-child) {
            font-weight: 400;
            margin-top: 6px;
        }
        
        &.code {
            padding: 4px;
            border-radius: 4px;
            background-color: #ffffff25;
            width: fit-content;
        }
    }
`

export const FriendsBox = styled.span`
    min-width: 60px;
    width: 100%;
    height: 70px;
    border-radius: 5px;
    background: ${p => p.theme["gray-100"]};

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: none;
        object-position: center;
    }

    @supports(not(display: grid)) {
        width: 64px;

        &:not(nth-child(-1n + 5)) {
            margin-top: 8px;
        }
    }
`

export const BadgesBox = styled.span`
    min-width: 60px;
    width: 100%;
    height: 60px;
    border-radius: 5px;
    background: ${p => p.theme["gray-100"]};

    display: flex;
    align-items: center;
    justify-content: center;

    @supports(not(display: grid)) {
        width: 64px;

        &:not(nth-child(-1n + 5)) {
            margin-top: 8px;
        }
    }
`

export const BadgeAndFriendsContainer = styled.div`
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12.5px;
    padding: 0 10px 10px 10px; */

    display: grid;
    gap: 6px;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 10px;
    padding-top: 0;

    @supports(not(display: grid)) {
        display: flex;
        flex-wrap: wrap;
    }
`

export const SimpleButton = styled.button`
    all: unset;
    font-weight: bold;
    font-size: ${p => p.theme["text-md"]};
    color: ${p => p.theme["blue-500"]}
`

export const SearchUserForm = styled.form`
    padding: 0 10px 10px 10px;

    div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-top: 10px;
    }
`

export const SearchUserBox = styled(Box)`
    @media (max-width: 768px) {
        grid-row: 3;
    }
`

export const AsideContent = styled.aside`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    gap: 30px;

    @supports(not(display: grid)) {
        min-width: 370px;
        max-width: 370px;

        & > *:not(:last-child) {
            margin-bottom: 30px;
        }
    }
`




export const RoomCard = styled.div`
    background: ${p => p.theme["white"]};
    box-shadow: 0px 7px 10px -10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    top: 0;
    transition: box-shadow 250ms, top 250ms;

    img {
        width: 100%;
        height: 132px;

        object-fit: none;
        object-position: center;
    }

    span {
        color: ${p => p.theme["gray-700"]};
        font-size: ${p => p.theme["text-md"]};
        font-weight: bold;
        text-align: center;

        width: 100%;
        padding: 16px 12px;
        border-top: 1px solid ${p => p.theme["gray-300"]};

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;

        cursor: default;
    }

    @supports(not(gap: 30px)) {
        width: calc(32.5% - 15px);

        &:not(:first-child), &:not(:nth-child(4n)) {
            margin-left: 30px;
        }

        &:not(nth-child(-1n + 3)) {
            margin-top: 30px;
        }
    }

    &:hover {
        box-shadow: 0px 7px 10px -5px rgba(0, 0, 0, 0.25);
        top: -3px;
    }
`

export const RoomsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @supports(not(display: grid)) {
        display: flex;
        flex-wrap: wrap;
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`

export const StoriesInfos = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    color: ${p => p.theme["white"]};
    font-size: ${p => p.theme["text-lg"]};
    font-weight: 900;

    position: absolute;
    bottom: 0;
    padding: 10px 20px;
    transform: translateY(100%);
    transition: transform 250ms;
`

export const StoriesCard = styled.div`
    width: 100%;
    flex-shrink: 0;
    height: 285px;
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    image-rendering: pixelated;
    position: relative;
    overflow: hidden;

    @supports(not(display: grid)) {
        width: calc(50% - 15);

        &:nth-child(2n) {
            margin-left: 30px;
        }

        &:not(:is(:first-child), :nth-child(2)) {
            margin-top: 30px;
        }
    }

    &::before {
        content: "";
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;

        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
        pointer-events: none;

        transform: translateY(100%);
        transition: transform 250ms;
    }

    &:hover::before,
    &:hover ${StoriesInfos} {
        transform: translateY(0%);
    }
`

export const StoriesContainer = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    @supports (not(display: grid)) {
        display: flex;
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;

        @supports (not(display: grid)) {
            flex-direction: column;
        }
    }
`

export const ShortInformationsContainer = styled.div`
    max-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;

    margin-top: 10px;

    @media (max-width: 768px) {
        display: none;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`

export const LongInformationField = styled.div`
    flex: 1;
    max-height: 100%;
    color: ${p => p.theme["gray-800"]};
    font-size: ${p => p.theme["text-md"]};

    padding: 12px;
    border-radius: 6px;
    background: ${p => p.theme["gray-100"]};

    word-break: break-all;
    overflow: hidden;

    &:not(:first-child) {
        margin-top: 2px;
    }

    strong {
        margin-right: 5px;
    }

    @media (min-width: 768px) {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

        max-height: 64px;
        line-height: 1.5;
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
`

export const MissionInformationField = styled(LongInformationField)`
    display: flex;
    flex-direction: row;

    strong { flex-shrink: 0; }
`

export const UserProfileInfos = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const UserProfileRow = styled.div`
    display: flex;
    gap: 12px;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
`

export const MainContent = styled.main`
    display: flex;
    flex-direction: column;
    gap: 30px;

    @supports(not(gap: 30px)) {
        margin-right: 30px;

        & > *:not(:last-child) {
            margin-bottom: 30px;
        }
    }
`

export const ProfileLayout = styled.div`
    width: 100%;
    max-width: 1000px;
    margin: auto;

    display: grid;
    grid-template-columns: 600px 370px;
    gap: 30px;

    @supports(not(display: grid)) {
        display: flex;
    }

    @media (max-width: 1040px) {
        grid-template-columns: 60% 1fr;
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`

export const Wrapper = styled.div`
    width: 100%;
    padding: 0 20px;
`