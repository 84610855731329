import { ArticleContainer, ArticleInfos, ArticleTopstory } from "./style";

export type ArticleProps = {
    title: string;
    description: string;
    topstoryUrl: string;
    id: number;
}

export function Article(props: ArticleProps) {
    return (
        <ArticleContainer to={`/comunidade/noticia/${props.id}`}>
            <ArticleTopstory src={props.topstoryUrl} alt="" />
            <ArticleInfos>
                <h3>{props.title}</h3>
                <span>{props.description}</span>
            </ArticleInfos>
        </ArticleContainer>
    )
}