import { Navigate, useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import { Box, BoxContent, BoxContentLink, BoxSmallHeader, BoxTitle } from "../../styles/global";
import { ArticleContentSkeleton, AsideContainer, ArticlePageLayout, MainContainer } from "./style";

import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ApisContext } from "../../Contexts/ApisContext";
import { endOfWeek, isWithinInterval, startOfWeek } from "date-fns";
import { ArticleComponent } from "./components/ArticleComponent";

type ArticlesProps = {
    title: string;
    description: string;
    label?: string;
    body: string;
    coverUrl: string;
    id: number;
    formId?: number;
    author: string;
    createdAt: string;
    likes: number;
}

export function ArticleTemplate() {
    const { Articles } = useContext(ApisContext)
    const { articleId } = useParams()
    const activeArticle = Articles[Number(articleId) - 1]
    const navigate = useNavigate()

    const todayDate = new Date()
    const lastWeekStartDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 7)
    const lastWeekEndDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 1)

    // Put article links according to it's creation date

    const firstDayOfCurrentWeek = startOfWeek(new Date())
    const lastDayOfCurrentWeek = endOfWeek(new Date())

    const {thisWeekArticles, lastWeekArticles, restArticles} = Articles.reduce((acumulator, article) => {
        if(isWithinInterval(new Date(article.createdAt), {
            start: firstDayOfCurrentWeek,
            end: lastDayOfCurrentWeek,
        })) {
            acumulator.thisWeekArticles.unshift(article)
        }
        
        else if(isWithinInterval(new Date(article.createdAt), {
            start: lastWeekStartDate,
            end: lastWeekEndDate
        })) {
            acumulator.lastWeekArticles.unshift(article)
        }
        
        else if(acumulator.restArticles.length < 25) {
            acumulator.restArticles.unshift(article)
        }

        return acumulator
    }, {
        thisWeekArticles: [] as ArticlesProps[],
        lastWeekArticles: [] as ArticlesProps[],
        restArticles: [] as ArticlesProps[],
    })


    // should redirect user to articles archive page
    function handleNavigate() {
        navigate("/comunidade/arquivo")
    }


    return (
        <ArticlePageLayout>
            <MainContainer>
                {Articles.find(article => article.id === Number(articleId)) !== null ?
                <ArticleComponent articleContent={activeArticle} />
                :
                <ArticleComponent articleContent={null} />
                }
            </MainContainer>

            <AsideContainer>
                <Box>
                    <BoxTitle variant="colorful">
                        Índice de notícias
                    </BoxTitle>

                    <BoxContent>
                        {thisWeekArticles.map(article => {
                            return <BoxContentLink key={article.id} to={`/comunidade/noticia/${article.id}`}>{article.title}</BoxContentLink>
                        })}
                    </BoxContent>

                    <BoxSmallHeader>Semana passada</BoxSmallHeader>

                    <BoxContent>
                        {lastWeekArticles.map(article => {
                            return <BoxContentLink key={article.id} to={`/comunidade/noticia/${article.id}`}>{article.title}</BoxContentLink>
                        })}
                    </BoxContent>

                    <BoxSmallHeader>Outras notícias</BoxSmallHeader>

                    <BoxContent>
                        {restArticles.map(article => {
                            return <BoxContentLink key={article.id} to={`/comunidade/noticia/${article.id}`}>{article.title}</BoxContentLink>
                        })}
                    </BoxContent>

                    <BoxContent>
                        <Button hasIcon={true} icon="plus" size="small" onClick={handleNavigate} type="button">
                            Todas as notícias
                        </Button>
                    </BoxContent>
                </Box>
            </AsideContainer>
        </ArticlePageLayout>
    )
}

export function RedirectToLatestArticle() {
    const { Articles } = useContext(ApisContext)
    const avatar = `https://habblive.in/imageeer.php?user=Habblive&action=wav&size=b&head_direction=2&direction=2&gesture=sml`

    return (
        <>
            <ArticleContentSkeleton />
            <Navigate to={`${Articles[Articles.length - 1].id}`} replace={true} />
        </>    
    )
}