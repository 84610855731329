import styled from "styled-components";

export const FaqContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    padding: 8px;
    background: ${p => p.theme["gray-100"]};
    border-radius: 4px;

    & + & {
        margin-top: 4px;
    }

    h4 {
        font-size: ${p => p.theme["text-sm"]};
        color: ${p => p.theme["black"]};
        font-weight: 700;
    }

    p {
        font-size: ${p => p.theme["text-sm"]};
        color: ${p => p.theme["black"]};
    }

    a {
        font-size: ${p => p.theme["text-sm"]};
        font-weight: bold;
        color: ${p => p.theme["blue-500"]};
        border-radius: 1px;

        &:hover {
            color: ${p => p.theme["blue-700"]};
        }

        &:focus {
            color: ${p => p.theme["blue-700"]};
            outline-color: ${p => p.theme["blue-500"]};
            outline-offset: 2px;
        }

        &:active {
            filter: brightness(0.95);
        }
    }
`

export const AsideContainer = styled.aside`
    display: flex;
    flex-direction: column;
    gap: 30px;

    @supports (not(display: grid)) {
        width: 100%;
        max-width: 370px;
    }

    @supports (not(gap: 30px)) {
        & > *:not(:last-child) {
            margin-bottom: 30px;
        }
    }
`

export const MainContainer = styled.main`
    display: flex;
    flex-direction: column;
    gap: 30px;

    @supports (not(display: grid)) {
        width: 100%;
        max-width: 600px;
        margin-right: 30px;
    }

    @supports (not(gap: 30px)) {
        & > *:not(:last-child) {
            margin-bottom: 30px;
        }
    }
`

export const PurchaseNicknamePageLayout = styled.div`
    width: calc(100% - 40px);
    max-width: 1000px;
    margin: auto;

    display: grid;
    grid-template-columns: 600px 1fr;
    gap: 30px;

    @media (max-width: 1040px) {
        grid-template-columns: 60% 1fr;
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
    
    @supports (not(display: grid)) {
        display: flex;
    }
`