import styled from "styled-components";
import { Box, BoxTitle } from "../../../styles/global";

export const ButtonsRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    
    gap: 12px;
    padding: 10px;
`

export const Form = styled.form`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding: 10px;
    padding-top: 0;

    label {
        font-size: ${p => p.theme["text-md"]};
        font-weight: 500;
        color: ${p => p.theme["black"]}
    }

    button {
        align-self: flex-end;
        margin-top: 10px;
    }
`

export const Container = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: fit-content;

    ${BoxTitle} {
        margin: 0;
    }
`