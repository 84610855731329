import { ReactNode } from "react";
import { ButtonLayout } from "./style";

import { FaAngleDown, FaArrowRight, FaPlus, FaShoppingBasket, FaThumbsDown, FaThumbsUp } from "react-icons/fa"

const icons = {
    "arrow-right": <FaArrowRight />,
    "thumbs-up": <FaThumbsUp />,
    "thumbs-down": <FaThumbsDown />,
    "plus": <FaPlus />,
    "angle-down": <FaAngleDown />,
    "shopping-basket": <FaShoppingBasket />,
}

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: ReactNode;
    variant?: "primary" | "secondary" | "success" | "danger";
    hasIcon?: boolean;
    icon?: keyof typeof icons;
    size?: "medium" | "small"
}

export function Button({hasIcon = false, icon = "arrow-right", size = "medium", variant = "primary", children, ...props}: IButton) {
    return (
        <ButtonLayout hasIcon={hasIcon} size={size} variant={variant} {...props}>
            <>
                {icons[icon]}
                {children}
            </>
        </ButtonLayout>
    )
}