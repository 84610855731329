import { BadgesRow, Box, BoxContent, BoxContentLink, BoxTitle } from "../../styles/global";
import { StaffCard } from "./Components/StaffCard";
import { AsideContainer, StaffPageLayout, MainContainer } from "../../styles/StaffPage";


export function OfficialsPage() {
    return (
        <StaffPageLayout>
            <MainContainer>

            <StaffCard role="Gestor" key={"Gestor"} />
            <StaffCard role="Fã-sites" key={"FaSites"} />
            <StaffCard role="Atividades" key={"Atividades"} />
            <StaffCard role="RPGs" key={"Rpg"} />

            </MainContainer>

            <AsideContainer>
                <Box>
                    <BoxTitle variant="colorful">
                        O que é a staff
                    </BoxTitle>
                    <BoxContent>
                        <span>
                            TEXTO AQUI
                        </span>
                        <BadgesRow>
                            <img src="/emblema-staff.gif" alt="" />
                        </BadgesRow>
                    </BoxContent>
                </Box>


                <Box>
                    <BoxTitle variant="colorful">
                        Trabalhe conosco
                    </BoxTitle>
                    <BoxContent>
                        <span>
                            TEXTO AQUI
                        </span>

                        <BoxContentLink to="/noticiaaqui">
                            Vaga para oficiais
                        </BoxContentLink>
                    </BoxContent>
                </Box>
            </AsideContainer>
        </StaffPageLayout>
    )
}