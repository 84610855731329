import { Article } from "../../components/Articles";
import { Button } from "../../components/Button";
import { FullTitle } from "../../components/FullTitle";
import { Tooltip } from "../../components/Tooltip";
import { Box, BoxTitle, Input, WarningBadge } from "../../styles/global";
import { DarkContainer, Form, LastArticlesRow, MainContainer, MainContent, RegisteredUserItem, RegisteredUsers, Wrapper } from "./styles";
import { ArticleProps } from "../../components/Articles"
import { DefaultDialog } from "./RegisterDialog";
import { FormEventHandler, useCallback, useContext, useRef, useState } from "react";
import { ApisContext } from "../../Contexts/ApisContext";
import Api from "../../App/Api";
import { useUser } from "../../hooks/users/useUser";
import Cookies from "universal-cookie";

type LoginResult = {
    reload?: boolean;
    error?: string;
    fields?: string[];

    banId?: number;
    banReason?: string;
    banExpires?: number;

    atlanta_ssid?: string;
    atlanta_uid?: string;
    atlanta_sti?: string;
    exp?: number;
}
// fake api

const fakeUsers = []

export function Index() {
    const { LastArticles } = useContext(ApisContext)
    const [loginError, setLoginError] = useState<string | null>(null)
    const { reloadUser } = useUser();
    const cookies = new Cookies();
    const ref = useRef<HTMLInputElement[]>([]);

    const login = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        ref.current.forEach(t => { t.classList.remove('error') });
        ref.current = [];
        const form = evt.target as HTMLFormElement;
        const username = (form[0] as HTMLInputElement).value;
        const password = (form[1] as HTMLInputElement).value;

        const loginResult: LoginResult = await Api.getInstance().postEncode('/user/login', { username, password });

        if (loginResult.reload) {
            await reloadUser();
            return;
        }

        if (loginResult.error) {
            setLoginError(loginResult.error);
            if (loginResult.fields) {
                loginResult.fields.forEach((field) => {
                    const input = form[field === 'username' ? 0 : 1] as HTMLInputElement;
                    ref.current.push(input);
                    input.classList.add('error');
                })
            }

            setTimeout(() => {setLoginError('')}, 5000);
        }
        else {
            const { atlanta_ssid, atlanta_sti, atlanta_uid, exp } = loginResult;
            if (atlanta_ssid && atlanta_sti && atlanta_uid && exp) {
                const date = new Date((exp * 1000));
                cookies.set('ATLANTA_SSID', atlanta_ssid, { expires: date });
                cookies.set('ATLANTA_UID', atlanta_uid, { expires: date });
                cookies.set('ATLANTA_STI', atlanta_sti, { expires: date });
                await reloadUser();
            }
        }
    }

    return (
        <MainContainer>
            <MainContent>
                <div>
                    <h2>Entre no Habblive</h2>
                    <DarkContainer>
                        <Form onSubmit={(e) => login(e)}>
                            {loginError ? <><WarningBadge>{loginError}</WarningBadge><br /></> : <></>}
                            <label>
                                Nickname
                                <Input type="text" placeholder="Joaozinho" />
                            </label>
                            <label>
                                Senha
                                <Input type="password" placeholder="***" />
                            </label>

                            <div>
                                <a href="">Esqueci minha senha</a>
                                <Button type="submit" variant="success">Entrar</Button>
                            </div>
                        </Form>

                        <Wrapper>
                            <Box>
                                <BoxTitle variant="clean">Últimos registrados</BoxTitle>

                                <RegisteredUsers>
                                    {fakeUsers.map(user => {
                                        return (
                                            <Tooltip content={user} key={user}>
                                                <RegisteredUserItem>
                                                    <img src={`https://habblive.in/imageeer.php?user=${user}&action=wav&size=b&head_direction=3&direction=3&gesture=sml&headonly=1`} alt="" />
                                                </RegisteredUserItem>
                                            </Tooltip>
                                        )
                                    })}
                                </RegisteredUsers>
                                <DefaultDialog>registre-se</DefaultDialog>
                            </Box>
                        </Wrapper>
                    </DarkContainer>
                </div>

                <FullTitle>Notícias recentes</FullTitle>

                <LastArticlesRow>
                    {LastArticles.map((article) => {
                        return (
                            <Article
                                title={article.title}
                                description={article.description}
                                topstoryUrl={article.coverUrl}
                                id={article.id}
                                key={article.id}
                            />
                        )
                    })}
                </LastArticlesRow>
            </MainContent>
        </MainContainer>
    )
}