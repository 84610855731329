import { formatDistance } from "date-fns"
import { ptBR } from "date-fns/locale"
import { Box, BoxTitle, WarningBadge } from "../../../../styles/global"
import { CardInfos, StaffBox, StaffContainer, StatusBadge, UserBadges } from "../../../../styles/StaffCard"

type StaffCardProps = {
    role: "Ceo" | "Diretor" | "Administrador" | "Moderação"
}

export function StaffCard(props: StaffCardProps) {
    const UserQuery = [
        {
            nickname: "Victor.M",
            joinedAt: "2019-11-24T00:14:36.675Z",
            isLogged: false,
            badges: [
                "/sample-badge.gif",
                "/sample-badge.gif",
            ]
        },
        {
            nickname: "SergioT",
            joinedAt: "2019-11-26T00:14:36.675Z",
            isLogged: false,
            badges: []
        },
        {
            nickname: "Blume",
            joinedAt: "2021-08-17T00:14:36.675Z",
            isLogged: false,
            badges: [
                "/sample-badge.gif",
                "/sample-badge.gif",
            ]
        },
        {
            nickname: "Danyel",
            joinedAt: "2022-04-05T00:14:36.675Z",
            isLogged: false,
            badges: [
                "/sample-badge.gif",
                "/sample-badge.gif",
            ]
        },
        {
            nickname: "Sublimis",
            joinedAt: "2022-02-29T00:14:36.675Z",
            isLogged: false,
            badges: []
        },
        {
            nickname: "MOD_Stela",
            joinedAt: "2022-07-30T00:14:36.675Z",
            isLogged: false,
            badges: [
                "/sample-badge.gif",
                "/sample-badge.gif",
            ]
        },
        {
            nickname: "MOD_Deluxe",
            joinedAt: "2022-08-12T00:14:36.675Z",
            isLogged: false,
            badges: [
                "/sample-badge.gif",
                "/sample-badge.gif",
            ]
        },
        {
            nickname: "MOD_Norrie",
            joinedAt: "2022-04-07T00:14:36.675Z",
            isLogged: false,
            badges: [
                "/sample-badge.gif",
                "/sample-badge.gif",
            ]
        },
    ]

    const Staff = {
        "Ceo": [
            "Victor.M",
            "SergioT",
        ],
        "Diretor": [
            "Blume",
        ],
        "Administrador": [
            "Danyel",
            "Sublimis",
        ],
        "Moderação": [
            "MOD_Stela",
            "MOD_Deluxe",
            "MOD_Norrie",
        ],
    }

    return (
        <Box>
            <BoxTitle variant="colorful">
                {props.role}
            </BoxTitle>
            
            <StaffContainer>
                {/* Warning if there isn't anyone at this role */}
                {Staff[props.role].length <= 0 && <WarningBadge>Ops, não há ninguém nesse cargo!</WarningBadge>}

                {/* Default process */}
                {Staff[props.role].length > 0 && Staff[props.role].map(each => {
                    const thisStaff = UserQuery.find(param => param.nickname === each)
                    const avatar = `https://habblive.in/imageeer.php?user=${thisStaff?.nickname}&action=wav&size=b&head_direction=3&direction=2&gesture=sml`
                    return (
                        <StaffBox key={thisStaff?.nickname}>
                            <img src={avatar} alt="" />

                            <CardInfos>
                                <h4>{thisStaff?.nickname}</h4>
                                <span>
                                    {`
                                        Na equipe há 
                                        ${
                                            formatDistance(new Date(thisStaff!.joinedAt), new Date(), {
                                                addSuffix: false,
                                                locale: ptBR,
                                            })
                                        }
                                    `}
                                </span>
                                <UserBadges>
                                    {thisStaff?.badges.map(badge => {
                                        return <img key={badge} src={badge} alt="" />
                                    })}
                                </UserBadges>

                                <StatusBadge isOnline={thisStaff!.isLogged}>{thisStaff?.isLogged ? "Online" : "Offline"}</StatusBadge>
                            </CardInfos>
                        </StaffBox>
                    )
                })}
            </StaffContainer>
        </Box>
    )
}