import { ReactNode } from "react"
import { Line, Title, TitleWrapper } from "./style"

type TitleProps = {
    children: ReactNode
}

export function FullTitle(props: TitleProps) {
    return (
        <TitleWrapper>
            <Title>
                {props.children}
            </Title>
            <Line />
        </TitleWrapper>
    )
}