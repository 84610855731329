import styled, { keyframes } from "styled-components";

// keyframes

export const openLightBoxKeyframe = keyframes`
    0% {
        transform: scale(0.9);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
`

export const LightBoxContainer = styled.dialog`
    margin: auto;
    position: fixed;
    inset: 0;
    background: transparent;
    border: none;
    width: 100%;
    height: 100vh;
    animation-fill-mode: both;

    &[open] {
        animation: ${openLightBoxKeyframe} 200ms;
    }

    &::backdrop {
        background-color: rgba(0, 0, 0, 0.5);  

        @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
        }
    }

    button {
        position: absolute;
        top: 10px;
        right: 10px;

        background: #00000030;
    }

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    * {
        max-width: 100%;
    }
`