import { createContext, ReactNode } from "react";

// 🧩 Types of partials

type OnlineCampaignProps = {
    coverUrl: string;
    title: string;
    description: string;
    articleID: number;
}

type LastArticlesProps = {
    title: string;
    description: string;
    coverUrl: string;
    id: number;
}

type PopularRoomProps = {
    roomName: string;
    roomOwner: string;
    usersAmount: number;
    roomId: number;
}

type VipUsersProps = {
    nickname: string;
    mission: string;
    endlineDate: string;
    isOnline: boolean;
}

type ArticlesProps = {
    title: string;
    description: string;
    label?: string;
    body: string;
    coverUrl: string;
    id: number;
    formId?: number;
    author: string;
    createdAt: string;
    likes: number;
}


// 🖼️ Api Context Types

type ApisContextProps = {
    OnlineCampaigns: OnlineCampaignProps[];
    LastArticles: LastArticlesProps[];
    PopularRooms: PopularRoomProps[];
    VipUsersInfo: VipUsersProps[];
    Articles: ArticlesProps[];
}


// 🤖 Function type

type ApiContextProiverProps = {
    children: ReactNode;
}

export const ApisContext = createContext({} as ApisContextProps)

export function ApisContextProvider({children}: ApiContextProiverProps) {
    const OnlineCampaigns: OnlineCampaignProps[] = [
        {
            coverUrl: "/default-campaign-topstory.png",
            title: "Energy Live",
            description: "Algo eletrizante acabou de se hospedar em nosso hotel, o que devemos esperar desse novo usuário?",
            articleID: 3,
        },
        {
            coverUrl: "/default-campaign-topstory.png",
            title: "Energy Live",
            description: "Algo eletrizante acabou de se hospedar em nosso hotel, o que devemos esperar desse novo usuário?",
            articleID: 4,
        },
        {
            coverUrl: "/default-campaign-topstory.png",
            title: "Energy Live",
            description: "Algo eletrizante acabou de se hospedar em nosso hotel, o que devemos esperar desse novo usuário?",
            articleID: 5,
        },
    ]

    const PopularRooms: PopularRoomProps[] = [
        {
            roomName: "X1 de Raros",
            roomOwner: "Parmesan",
            usersAmount: 76,
            roomId: 24534,
        },
        {
            roomName: "GEA - Stranger Live",
            roomOwner: "GeA",
            usersAmount: 73,
            roomId: 49274,
        },
        {
            roomName: "Recepção",
            roomOwner: "Habblive",
            usersAmount: 73,
            roomId: 43263,
        },
        {
            roomName: "Terraço - Encontre seu amor",
            roomOwner: "Habblive",
            usersAmount: 65,
            roomId: 43984,
        },
        {
            roomName: "Família Ricardo",
            roomOwner: "Jade_Ricardo",
            usersAmount: 43,
            roomId: 93445,
        },        {
            roomName: "Vendo raros",
            roomOwner: "Parmesan",
            usersAmount: 42,
            roomId: 98345,
        },
        {
            roomName: "RPG Hogwarts - Salão principal",
            roomOwner: "Dumbledore",
            usersAmount:4,
            roomId: 98245,
        },
        {
            roomName: "RPG Hogwarts",
            roomOwner: "Dumbledore",
            usersAmount: 38,
            roomId: 59385,
        },
        {
            roomName: "Danna fracassade sempre",
            roomOwner: "Floricultor",
            usersAmount: 37,
            roomId: 94858,
        },
    ]

    const VipUsersInfo: VipUsersProps[] = [
        {
            nickname: "Parmesan",
            mission: "web diva tulla luana 59",
            isOnline: false,
            endlineDate: "2022-11-23T22:15:34.725Z",
        },
        {
            nickname: "Nayara",
            mission: "oi :)",
            isOnline: true,
            endlineDate: "2022-11-23T22:18:11.433Z",
        },
        {
            nickname: "Harder",
            mission: "VENDO RAROS E SUPER RAROS ADD AI",
            isOnline: true,
            endlineDate: "2022-12-21T22:18:11.433Z",
        },
        {
            nickname: "Blume",
            mission: "sou a melhor de todas.",
            isOnline: false,
            endlineDate: "2022-05-23T22:18:11.433Z",
        },
        {
            nickname: "vini259.",
            mission: "im gay",
            isOnline: false,
            endlineDate: "2024-08-30T22:18:11.433Z",
        },
    ]

    const Articles: ArticlesProps[] = [
        {
            title: "Billy, o Golfinho",
            description: "Sempre doce e carismático.",
            coverUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbazhXusIaFpQ_qPA8QWh7rFEPOMtga0qM9ECJ9iD-fQ&s",
            id: 1,
            author: "Huagge",
            body: `
            <p>Morador do imenso e do infinito oceano, este mamífero costuma conquistar o coração de todos os seres humanos. Inofensivos e sempre alegres, despertam o interesse das pessoas e sempre que podem, visitam um aquário ou uma região onde os golfinhos amam se mostrar ao público.</p>

            <p>Billy tem apenas 6 meses de idade, sua mãe morreu durante seu parto em algum lugar no mar obscuro e encoberto. Mesmo sendo órfão, isso jamais o tornou solitário ou abatido, mas fez ele ser carismático, ao ponto das pessoas atravessarem o pélago apenas para vê-lo pular da água.</p>

            <p><strong>Mamífero Simpático</strong></p>

            <p>Dito isso, sabendo um pouco da história de Billy, elabore um desenho de um golfinho para que ele seja homenageado pela sua linda trajetória. A ilustração deve ser detalhada e bem criativa. Não se esqueça de escrever o seu nickname assim que terminar. Não se limite, uma boa sorte!</p>

            <p>Após isso, remeta o link de sua imagem no formulário logo abaixo, vale lembrar que é para ser feito de forma individual. O prazo será até o findar do dia 29 de Novembro. Aos campeões: 1 ponto no Hall, 1 promocoin e a insígnia exposta. Aguardo por todos vocês, nos encontraremos.</p>
            `,
            createdAt: "2022-11-25T23:06:55Z",
            likes: 12,
            label: "Promoção"
        },
        {
            title: "Torcedor Fiel",
            description: "Dê o seu palpite na partida de hoje!",
            coverUrl: "https://habbotoolss.weebly.com/uploads/1/3/3/8/13386862/8600290.png",
            id: 2,
            author: "Pearl",
            body: `
                <p>A espera foi longa, mas a paixão pelo futebol nunca saiu dos nossos corações, bela paixão que arde no alegre e almejado grito de gol, vestido com o manto, naquele churrasquinho... Toda a emoção toma conta, enquanto não for soado o esperado apito final, nossa fé não será retraída.</p>

                <p>A empolgante torcida empurra o time durante os noventa minutos, até quando as penalidades entram em campo. Os cantos, maquiagens, bandeiras, nos faz acreditar que o mundo é verde e amarelo, de fato, a carismática e exultante torcida sempre acompanhará as nossas estrelas.</p>

                <p><strong>Mostra tua força, Brasil!</strong/></p>

                <p>Conquistar a sexta estrela é o nosso objetivo, assim, chegou a sua hora de entrar em campo e mostrar que é bom do palpite. No formulário abaixo, faça a aposta do placar do primeiro jogo. Tal insígnia será cedida aos participantes. Os palpites corretos ganharão um raro customizado.</p>
            `,
            createdAt: "2022-11-25T23:10:05Z",
            likes: 43,
            label: "Promoção"
        },
        {
            title: "Pucca",
            description: "Descrição da notícia teste 3",
            coverUrl: "https://habbotoolss.weebly.com/uploads/1/3/3/8/13386862/2191865.png",
            id: 3,
            author: "Vivi",
            body: `
                <p>Pucca é coreana, mas mora com o seu pai na vila de Sooga que fica localizada na China. É filha única do dono do restaurante chinês Guh-Ryong, ela ama um bom macarrão, mas, sobretudo é apaixonada por um ninja que possui o nome Garu, ele tenta desesperadamente fugir da Pucca.</p>

                <p>No desenho, ela não fala mas continua a usar os sons de seus cartoons. Fica vermelha de raiva quando suas vontades não são realizadas e quando fica pensativa aparecem três pontinhos em cima de sua cabeça. Possui um belo sorriso muito chamativo e um penteado que a torna única.</p>
                
                <p><strong>Vestimenta Afamada</strong></p>
                
                <p>As roupas da Pucca são bastante diferentes, mas o que mais a caracteriza é a camisa vermelha e a calça preta. Sabendo disso, um desafio instigante é proposto! Utilizando o guarda-roupa do hotel, elabore um visual da Pucca. Seja criativo, apenas os melhores envios vão ser aceitos.</p>
                
                <p>Hospede o seu printscreen do visual e envie até o fim do dia 26 de Novembro. Os vencedores embolsarão o belíssimo emblema exibido logo adiante. Posto isso, me despeço, beijinhos e até!</p>
            `,
            createdAt: "2022-11-25T23:12:29Z",
            likes: 3,
        },
        {
            title: "Afinidade Abrasadora",
            description: `"Amor é fogo que arde sem se ver"`,
            coverUrl: "https://habbotoolss.weebly.com/uploads/1/3/3/8/13386862/2191865.png",
            id: 4,
            author: "Sublimis",
            body: `
                <p>Tão escaldante como chamas de fogo, este é um dos sentimentos mais desprezado pela mente humana que não tem a coragem suficiente para lhe tocar diretamente. Uma remota distância é mantida por esta emoção apaixonada que tenta queimar todos aqueles que chegam perto dela.</p>

                <p>Encontrando a bravura competente, semelhanças são convocadas para aproximar corações que apresentam a sua cara metade. A separação central destes interiores pode causar fortes fontes de sofrimento que têm como missão acabar com a empatia gerada pelas primeiras impressões.</p>
                
                <p><strong>Afeiçoamento Ardente</strong></p>
                
                <p>Equivalente a um tecido consumado e descosido, esta sensação amorosa é vencida pelo tempo que aumenta cada vez mais a sua grandiosa fragilidade. Fortalecido pelas vivências carinhosas, o aconchego concebido entre duas almas iguais abriga o papel principal em suas mentalidades.</p>
                
                <p>Refletindo sobre o tema e utilizando uma folha de papel, o seu dever é engendrar uma venusta dobradura de coração. Este encargo expirará no cessar do dia 24 de Novembro e premiará os autores dos envios triunfantes com a elogiável insígnia, uma Promocoin e um ponto no Hall.</p>
            `,
            createdAt: "2022-11-25T23:15:29Z",
            likes: 21,
            label: "Campanha",
        },
        {
            title: "A adolescência de Kevin",
            description: "A fase de desenvolvimento mais importante da vida",
            coverUrl: "https://habbotoolss.weebly.com/uploads/1/3/3/8/13386862/2191865.png",
            id: 5,
            author: "Huagge",
            body: `
                <p>A vida passa por profusas etapas em vários momentos distintos, desde o nascimento até a sua morte, muitas coisas podem ou não acontecer. De certo que, numerosas pessoas nem viram a luz no teto da sala de partos no hospital, pois partiram antes, já outros vivem cheios de forças.<p>

                <p>O processo desde a infância até chegar a adolescência revela muitas coisas. Um pedaço no que chamamos de linha do tempo, onde as crianças são aprimoradas em seu intelecto e já instaura pensamentos e curiosidades a respeito da vida, etapas que devem ser vividas com muito amor.<p>
                
                <p>Há quem diga que o ensino médio é o pior cenário para um adolescente, podendo piorar se ele for tímido e não conseguir se relacionar com as pessoas em sua volta. Outras turmas dirão que não, elas falarão que a pior época é quando se apaixona por alguém e não é recíproco, terrível.<p>
                
                <p>Sabemos que esse momento é cheio de emoções indefinidas e aventuras memoráveis, por isso nós iremos acompanhar essa nova fase na vida do Kevin, viveremos suas histórias e teremos o conhecimento de como ele irá se sair nesse novo mundo que é a mocidade. Nos veremos logo.<p>

                <p style="text-align: center;"><strong>Redatores</strong></p>
            `,
            createdAt: "2022-12-03T18:18:08Z",
            likes: 1,
            label: "Promoção"
        },
        {
            title: "Copa Colecionável",
            description: "Complete o seu gramado de voodos limitados!",
            coverUrl: "https://images.habbo.com/c_images/article_images_br/art_football_goooal.png",
            id: 6,
            author: "Blume",
            body: `
                <style>
                    table {
                        border-collapse: collapse;
                    }
                    table td {
                        padding: 15px;
                    }
                    table thead td {
                        background-color: #54585d;
                        color: #ffffff;
                        font-weight: bold;
                        font-size: 13px;
                        border: 1px solid #54585d;
                    }
                    table tbody td {
                        color: #282828;
                        border: 1px solid #a4a6a9;
                    }
                    table tbody tr {
                        background-color: #f9fafb;
                    }
                    table tbody tr:nth-child(odd) {
                        background-color:#e7e1e1;
                    }
                </style>
                <p style="text-align: center;"><img src="https://cdn.discordapp.com/attachments/1019767175357149185/1048041095671586978/sdadadadad.png" /></p>
                
                <p>Rolou a bola no universo de pixels! Estamos em tempos de <strong>Copa do Mundo</strong> e os hóspedes do hotel não ficariam sem tamanho entretenimento futebolístico. Pensando nisso, um cronograma com incontáveis lances imperdíveis foi engedrado para promover a diversão sem impedimento.</p>
                
                <p>Para que essa época de divertimento fique completa, itens colecionáveis serão disponibilizados ao longo das jogatinas. Participe para as adquirir e, destarte, completar o seu gramado com as seleções que já conquistaram o almejado título de campeã. Averigue as informações expostas:</p>
                
                <table border="1" cellpadding="1" cellspacing="1" style="width:500px; margin: auto;">
                    <tbody>
                        <tr>
                            <td style="text-align: center;">FutStrike, por LiveEvil<br />
                            06/12, às 18h (+3 PT)</td>
                            <td style="text-align: center;">Gol a Gol, por SawClub<br />
                            07/12, às 18h (+3 PT)</td>
                        </tr>
                        <tr>
                            <td style="text-align: center;">Pênalti, por JogosOficiais<br />
                            08/12, às 18h (+3 PT)</td>
                            <td style="text-align: center;">Futblokeio, por Blume<br />
                            09/12, às 18h (+3 PT)</td>
                        </tr>
                        <tr>
                            <td style="text-align: center;">Goleiraço, por Moderat<br />
                            10/12, às 18h (+3 PT)</td>
                            <td style="text-align: center;">Gândula, por Gustavo<br />
                            11/12, às 18h (+3 PT)</td>
                        </tr>
                    </tbody>
                </table>
                </p>
                
                <p>As atividades permanecerão acessíveis ao longo de uma semana à partir da data de início. Aos dez primeiros de cada placar, um dos voodos torcedores serão entregues, além dos emblemas. Então, não fique de fora destas jogatinas imperdíveis e, tal qual a seleção, evidencie sua força!</p>
                
                <p><img alt="Setinhas para notícias" src="https://cdn.discordapp.com/attachments/1014620322781220916/1052461542723035166/JMpq11D.png" /> Compre o gramado disponível na seção "Copa Colecionável" e preencha-o com os raros.</p>
                
                <p style="text-align: center;"><img src="https://media.discordapp.net/attachments/1014620322781220916/1052461498770923530/vsAjcGH.png" />
            `,
            createdAt: "2022-12-06T18:18:08Z",
            likes: 1,
            label: "Promoção"
        },
        {
            title: "HVSA: Página Garbosa",
            description: "Uma poderosa chama ardente foi avistada nos céus, capture-a e descubra seu sentido.",
            coverUrl: "https://i.imgur.com/4LyCREA.png",
            id: 7,
            author: "Fairy",
            body: `
                <script>var nothing=new Audio("")
                window.onload=function(){nothing.play();}</script>
                <style>
                .truck-rod {
                    height: 100%;
                    width: 0;
                    position: fixed;
                    z-index: 999999999999999999;
                    top: 0;
                    left: 0;
                    overflow-x: hidden;
                    transition: 0.5s;
                    background-color: #2c2b2e;
                    background-repeat: no-repeat;
                    background-attachment: fixed;
                    background-position: right bottom;
                    }

                    .truck-rod-content {
                    position: relative;
                    top: 25%;
                    width: 100%;
                    text-align: left;
                    margin-top: 30px;
                    }

                    .truck-rod a {
                    color: #2b2931;
                    text-decoration: none;
                    transition: 0.3s;
                    }

                    .truck-rod .closebtn {
                    position: absolute;
                    top: 20px;
                    right: 45px;
                    font-size: 38px;
                    z-index: 999999999999999999999999999999999999999;
                    }

                    @media screen and (max-height: 450px) {
                    .truck-rod a {
                        font-size: 20px;
                    }

                    .truck-rod .closebtn {
                        font-size: 40px;
                        top: 15px;
                        right: 35px;
                    }
                    }

                    .center-bb1 {
                    display: block;
                    margin-right: auto;
                    margin-left: auto;
                    }

                    .panel-rod-9999 {
                    margin-bottom: 20px;
                    border: 1px solid transparent;
                    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
                    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
                    border-radius: 10px;
                    background-color: #2b2931;
                    border-color: #000;
                    }

                    .panel-rod-9999-default,
                    .panel-rod-9999-heading {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    }

                    .panel-rod-9999-heading {
                    padding: 15px 20px;
                    }


                    .panel-rod-9999-default,
                    .panel-rod-9999-footer {
                    color: #fff;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    background-color: #2c2b2e;
                    }

                    .panel-rod-9999-footer {
                    padding: 15px 20px;
                    border-top: 1px solid #000;
                    }

                    .text-center {
                    text-align: center;
                    }

                    .panel-rod-9999-body {
                    padding: 15px;
                    }

                    .panel-rod-9999-body:before,
                    .row:after,
                    .row:before {
                    display: table;
                    content: " ";
                    }

                    :after,
                    :before {
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    }
                    .imgs1 {
                    float: right;
                    }

                body {
                    background-image: url(https://i.imgur.com/rGRcEsU.png);
                }

                /*removendo fundo da página de notícias*/
                #column2 div.bt { background-image: none; }
                #column2 h2 {display:none;}
                #column2 div.i1, .i1 { background-image: none; }
                #column2 div.i2 {margin:none;background-image:none;}
                #column2 .bt div {display:none;}
                #column2 div.bb {display:none;}
                #column2 div.i3 {background:transparent;}
                div#article-wrapper hr {display:none;}
                #article-wrapper font {display:none;}
                #article-wrapper p.summary {display:none;}
                /*fundo da área de notícia, ajuste com padding, witdh e height.*/
                #article-wrapper {
                    background: url(https://i.imgur.com/fpcNwVq.png) no-repeat;
                    padding: 14px;
                    width: 540px;
                    height: 940px;
                    background-position-y: 0px, center;
                }


                #header-container {
                    background: url(https://i.imgur.com/KmQartp.png), linear-gradient(to top, #59d9ff 20%, #90e3fb 50%, #78f9ff 80%) !important;
                }

                #navi2 ul li a {
                    color: #2b2931;
                }

                #header h1 a, #header h1 span {
                background-image: url(https://i.imgur.com/Vp1JygF.png) !important;
                }

                #navi li strong, #navi li a {
                    background-image: url(https://i.imgur.com/ZffE7Yy.png) !important;
                }

                #navi li span {
                    background-image: url(https://i.imgur.com/ZffE7Yy.png) !important;
                }

                #blauw22 {
                    background: linear-gradient(to bottom,#395757 35%,#2d4444 35%) !important;
                }

                div#article-archive font {
                    color: #2c2b2e;
                }

                b font {
                    color: #2b2931;
                }

                div.section.avatar div.onlinehabbos {
                    border: 5px solid #2b2931;
                }

                div.section.avatar div.onlinehabbos div.arrow_border {
                border-left: 12px solid #2c2b2e!important;
                }

                .floating { 
                    animation-name: floating;
                    animation-duration: 3s;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease-in-out;
                    margin-top: 5px;
                }
                
                @keyframes floating {
                    0% { transform: translate(0,  0px); }
                    50%  { transform: translate(0, 15px); }
                    100%   { transform: translate(0, -0px); }   
                }
                </style>

                <style type="text/css">* 
                {cursor: url(https://i.imgur.com/gTD9o2C.png), auto !important;}
                </style>

                <p style="text-align: center;"><img src="https://cdn.discordapp.com/attachments/943197182004199467/1070831012633923718/image.png" /></p>

                <p>Após o acontecimento mágico com seu cabelo, a garota, chamada Sofia, acorda de um sonho e se entristece pelos fatos terem parecido reais. Desta mesma maneira, no ponto crucial de todo centro do planeta, um fogo arroxeado foi avistado no céu azul que iluminava aquela bela tarde.</p>

                <p>Curiosos e assustados, a equipe de proteção foi esperável para proteger todos que estavam no local. O iluminar da chama roxeada despertou mais curiosidade nos presentes, logo a notícia e, de fato, as fofocas se espalharam pelo planeta, atraindo algumas pessoas para essa localidade.</p>

                <p style="text-align: center;"><img alt="" src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/cf2836cb-5893-4a6c-b156-5a89d94fc721/dbdpcze-76dddb79-22d4-4640-869e-b6a09c271f82.gif?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2NmMjgzNmNiLTU4OTMtNGE2Yy1iMTU2LTVhODlkOTRmYzcyMVwvZGJkcGN6ZS03NmRkZGI3OS0yMmQ0LTQ2NDAtODY5ZS1iNmEwOWMyNzFmODIuZ2lmIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.CIR4LMFPGHr63qZGFF9m107jjnk6zprGoRi-4m_8gR0" /></p>

                <p>Um fogo em seu nariz, Sofia esclarece e se pergunta o motivo de ter sonhado com a mesma e, diversos, outros monstros tenebrosos. Chegando ao local e assustada com tamanha destruição aparente, a garota se enfurece e, num piscar do olho, novamente seu cabelo brilha como a luz.</p>

                <p>Atento a isso, utilize um aplicativo digital e elabore uma tirinha que retrate o acontecimento. A tirinha deve conter no máximo <strong>4</strong> partes. O prazo de duração se estende até o dia <strong>08/02</strong>. Todo vencedor receberá 1 ponto no Hall, a insígnia exposta no final e uma promocoin. Dito, abraços! </p>

                <p><img alt="" src="https://cdn.discordapp.com/attachments/943197182004199467/1070371908593406024/image.png" style="float: right; width: 48px; height: 16px;" /></p> 
                 

                <p style="text-align: center;"><img src="https://cdn.discordapp.com/attachments/1066193133869736067/1066467643864928256/ES31M.png" /></p>

                <p style="text-align: center;"><a href="https://hlive.ovh/adms/formbuilder/external/3014" target="_blank"><img class="form-image" data-lightbox-hidden src="https://cdn.discordapp.com/attachments/943197182004199467/1070192636348088461/image.png" /></a></p>

                <p style="text-align: center;"><img src="https://cdn.discordapp.com/attachments/1048443689741062194/1048454407001489458/UcEw7i5.png" /><a href="https://habblive.in/noticias/12732" target="_blank"><span style="color:#7f8c8d;"><u><strong>Clique e confira a campanha "Humans VS Aliens".</strong></u></span></a>

                <p style="text-align: center;"> </p>

                <p style="text-align: center;"><img alt="" src="https://i.imgur.com/1FfyFzc.png" /></p>

                <script>
                    function dIca1() {
                    document.getElementById("track").style.width = "100%";
                    }

                    function rodx() {
                    document.getElementById("track").style.width = "0%";
                    }
                </script>
                <div id="track" class="truck-rod">
                    <a href="javascript:void(0)" class="closebtn" onclick="rodx()">×</a>
                    <div class="truck-rod-content">
                <div class="panel-rod-9999a center-bb1" style="margin-top:-10%;width:35%;">
                        <div class="panel-rod-9999-heading text-center">
                <img src="https://i.imgur.com/qDuCzKw.png" style="position:absolute;margin-left:-170px" />

                </div>
                </div>

                <div style="display:none;">

                <style>div.bb{display:none}</style>
                <div style="display:none;">

                <style type="text/css">*
                *::-webkit-scrollbar{
                    width:15px
                }
                *::-webkit-scrollbar-thumb{
                    background-color:#395757;
                    border-radius:6px
                    border:12px double #2d4444;
                }
                ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 10px; 
                background: #395757;
                } 
                </style>

                <style>div.bb{display:none}</style>
                <div style="display:none;">

                <style>

                #article-archive h2{
                    }
                    #article-archive h2{
                        font-size:100%;
                        border-bottom:0 dashed;
                        padding:6px;
                        border-radius:3px;
                        color:#3b3b3b;
                        background: linear-gradient(to bottom,#6d6d6d50 35%, #6d6d6d50 35%)
                    }
                    #article-archive li aa{
                        position:relative;
                        font-size:100%;
                        border-bottom:0 dashed;
                        padding:6px;
                        border-radius:3px;
                        color:#000;
                        max-width:180px;
                        min-width:180px;
                        width:180px;
                        background:linear-gradient(to bottom,#151a1d 35%,#151a1d 35%)
                    }
                    #article-archive li:hovera{
                        font-size:100%;
                        border-bottom:0 dashed;
                        padding:6px;
                        border-radius:3px;
                        color:#000;
                        background:linear-gradient(to bottom,rgba(9,11,12,.71) 35%,rgba(9,11,12,.71) 35%)
                    }
                    #article-archive li{
                        font-size:100%;
                        border-bottom:0 dashed;
                        padding:6px;
                        border-radius:3px;
                        color:#000;
                        background:linear-gradient(to bottom,rgba(201,201,201,.71) 35%,rgba(201,201,201,.71) 35%)
                    }
                    #article-archive ul{
                        margin:0 0 0 0
                    }
                    #article-archive li a{
                        color:#000;
                        text-decoration:none
                    }
                #blauw-nt{
                        position:relative;
                        background:linear-gradient(to bottom,#76a932 35%,#76a932 35%);
                        border-radius:3px;
                        height:44px;
                        width:541px;
                        margin-top:-79px;
                        margin-left:-5px;
                        z-index:1
                    }
                    #blauw-ntb{
                        position:relative;
                        background:linear-gradient(to bottom,#5d8628 35%,#5d8628 35%);
                        border-radius:3px;
                        height:44px;
                        width:541px;
                        margin-top:-18px;
                        margin-left:-5px;
                        z-index:0
                    }
            `,
            createdAt: "2023-02-04T17:18:08Z",
            likes: 1,
            label: "Promoção"
        }

    ]

    const LastArticles = [
        // from latest to oldest

        Articles[Articles.length - 1],
        Articles[Articles.length - 2],
        Articles[Articles.length - 3],
    ]

    return (
        <ApisContext.Provider value={{OnlineCampaigns, LastArticles, PopularRooms, VipUsersInfo, Articles}}>
            {children}
        </ApisContext.Provider>
    )
}