import { ReactNode } from "react"
import { Arrow, Provider, Content, Portal, Root, Trigger } from "./style"

type ContentProps = {
    children?: ReactNode;
}

type TooltipProps = {
    children: ReactNode;
    content: string | ReactNode;
}

function PortalContent({children}: ContentProps) {
    return (
        <Portal>
            <Content avoidCollisions collisionPadding={5} align="center" side="top" sideOffset={5}>
                {children}
                <Arrow width={15} height={7} />
            </Content>
        </Portal>
    )
}

export function Tooltip(props: TooltipProps) {
    return (
        <Provider delayDuration={300}>
            <Root>
                <Trigger asChild>{props.children}</Trigger>
                <PortalContent>{props.content}</PortalContent>
            </Root>
        </Provider>
    )
}