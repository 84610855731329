import { BadgesRow, Box, BoxContent, BoxContentLink, BoxTitle } from "../../styles/global";
import { StaffCard } from "./Components/StaffCard";
import { AsideContainer, StaffPageLayout, MainContainer } from "../../styles/StaffPage";


export function ColaboratorsPage() {
    return (
        <StaffPageLayout>
            <MainContainer>

            <StaffCard role="Embaixador" key={"Embaixador"} />
            <StaffCard role="Guardião" key={"Guardião"} />
            <StaffCard role="Ajudante" key={"Ajudante"} />

            </MainContainer>

            <AsideContainer>
                <Box>
                    <BoxTitle variant="colorful">
                        O que é a staff
                    </BoxTitle>
                    <BoxContent>
                        <span>
                            TEXTO AQUI
                        </span>
                        <BadgesRow>
                            <img src="/emblema-staff.gif" alt="" />
                        </BadgesRow>
                    </BoxContent>
                </Box>


                <Box>
                    <BoxTitle variant="colorful">
                        Trabalhe conosco
                    </BoxTitle>
                    <BoxContent>
                        <span>
                            TEXTO AQUI
                        </span>

                        <BoxContentLink to="/noticiaaqui">
                            Ajudantes
                        </BoxContentLink>
                    </BoxContent>
                </Box>
            </AsideContainer>
        </StaffPageLayout>
    )
}