import CryptoJS from 'crypto-js';
import { Buffer } from 'buffer';

const keySize = 256;
const ivSize = 128;
const saltSize = 256;
const iterations = 1000;

export function CryptoJSAesEncrypt1(passphrase: string, plain_text: string) {

    const salt = CryptoJS.lib.WordArray.random(32);
    const iv = CryptoJS.lib.WordArray.random(16);

    const key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999 });

    const encrypted = CryptoJS.AES.encrypt(plain_text, key, { iv: iv });

    const data = {
        ciphertext: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
        salt: CryptoJS.enc.Hex.stringify(salt),
        iv: CryptoJS.enc.Hex.stringify(iv)
    }

    return JSON.stringify(data);
}

export function CryptoJSAesEncrypt(pass: string, msg: string) {
    const salt = CryptoJS.lib.WordArray.random(saltSize / 8);

    const key = CryptoJS.PBKDF2(pass, salt, {
        keySize: keySize / 32,
        iterations: iterations
    });
    const iv = CryptoJS.lib.WordArray.random(ivSize / 8);

    const encrypted = CryptoJS.AES.encrypt(msg, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC

    });

    const encryptedHex = base64ToHex(encrypted.toString());
    const base64result = hexToBase64(salt as any + iv as any + encryptedHex);


    return base64result;
}

export function decrypt(transitmessage: string, pass: string) {

    const hexResult = base64ToHex(transitmessage)

    const salt = CryptoJS.enc.Hex.parse(hexResult.substr(0, 64));
    const iv = CryptoJS.enc.Hex.parse(hexResult.substr(64, 32));
    const encrypted = hexToBase64(hexResult.substring(96));

    const key = CryptoJS.PBKDF2(pass, salt, {
        keySize: keySize / 32,
        iterations: iterations
    });

    const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC

    })

    return decrypted.toString(CryptoJS.enc.Utf8);
}
function hexToBase64(str: string) {
    return btoa(String.fromCharCode.apply(null,
        str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ") as any)
    );
}

function base64ToHex(str: string) {
    for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
        var tmp = bin.charCodeAt(i).toString(16);
        if (tmp.length === 1) tmp = "0" + tmp;
        hex[hex.length] = tmp;
    }
    return hex.join("");
}
export function btoe(st: string) {
    return Buffer.from((st), 'binary').toString('base64')
}