import { useCallback, useEffect, useState } from "react";
import { useBetween } from "use-between"
import { defaultUser, User } from "../../api/users/User";
import Api from "../../App/Api";
import { useLoading } from "../loading/useLoading";

const useUserState = () => {

    const [user, setUser] = useState<User>(defaultUser);
    const { pushItem, shiftItem } = useLoading();

    const reloadUser = async () => {
        pushItem();
        const userResult: User = await Api.getInstance().get('/user/me');

        if (userResult) setUser(userResult);
        shiftItem();

    }

    useEffect(() => {
        reloadUser();

    }, []);

    return { user, reloadUser }
}

export const useUser = () => useBetween(useUserState);

