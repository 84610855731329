import { useEffect, useState } from "react";
import { useBetween } from "use-between"

const useLoadingState = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [itemsToLoading, setItemsToLoading] = useState<number>(0);
    const [startCount, setStartCount] = useState<boolean>(false);

    const setLoading = (loading: boolean) => {
        setIsLoading(loading);
    }

    const pushItem = () => {
        setItemsToLoading((prev) => prev+1);
        setLoading(true);
        setStartCount(true);
    }

    const setTotalItems = (total:number) => {

        setItemsToLoading(total);

        if(total > 0) setLoading(false);

        
        setStartCount(true);
    }

    const shiftItem = () => {
        setItemsToLoading((prev) => prev-1)
    }

    useEffect(() => {
        if(itemsToLoading === 0 && isLoading && startCount){
            setLoading(false);
        }
    }, [itemsToLoading])

    return { isLoading, setTotalItems, shiftItem, pushItem }
}

export const useLoading = () => useBetween(useLoadingState);