import { ReactNode } from "react"
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Content, Group, Icon, Item, ItemText, Label, Portal, ScrollDownButton, ScrollUpButton, Select, Separator, Trigger, Value, Viewport } from "./style"

type SelectItemProps = {
    children: ReactNode;
    value: string;
}

function SelectItem({children, value}: SelectItemProps) {
    return (
        <Item value={value}>
            <ItemText>
                {children}
            </ItemText>
        </Item>
    )
}

export function SortBySelect() {
    return (
        <Select>
            <Trigger aria-label="Filter method">
                <Value placeholder="Ordenar por..." />
                <Icon>
                    <FaAngleDown size={17} />
                </Icon>
            </Trigger>

            <Portal>
                <Content>
                    <ScrollUpButton>
                        <FaAngleUp size={16} />
                    </ScrollUpButton>

                    <Viewport>
                        <Group defaultValue={"recent-connection"}>
                            <Label>Escolha um método</Label>

                            <Separator />

                            <SelectItem value="recent-connection">Último login</SelectItem>
                            <SelectItem value="nickname">Nickname A-Z</SelectItem>
                            <SelectItem value="motto">Missão A-Z</SelectItem>
                            <SelectItem value="city-name">Cidade A-Z</SelectItem>
                        </Group>
                    </Viewport>

                    <ScrollDownButton>
                        <FaAngleDown size={16} />
                    </ScrollDownButton>
                </Content>
            </Portal>
        </Select>
    )
}