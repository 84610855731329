import { FaAngleDoubleUp } from "react-icons/fa"
import { ButtonLayout } from "./style"

const GoToTop = () => {
    window.scrollTo(0, 0)
}

export function GoToTopButton() {
    return (
        <ButtonLayout onClick={GoToTop} type="button" aria-label="Go to the top of the page" title="Ir para o topo"><FaAngleDoubleUp size={20} /></ButtonLayout>
    )
}