import { ThemeProvider } from "styled-components"
import { GlobalStyle } from "./styles/global"
import { defaultTheme } from "./styles/themes/default"
import { Router } from "./router"
import { UserContextProvider } from "./Contexts/UserContext"
import { ApisContextProvider } from "./Contexts/ApisContext"

export function App() {

  return (<>

    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />

      <UserContextProvider>
        <ApisContextProvider>
          <Router />
        </ApisContextProvider>
      </UserContextProvider>
    </ThemeProvider></>
  )
}

export default App
